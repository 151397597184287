import type {
  ButtonProps as ChakraButtonProps,
  IconButtonProps as ChakraIconButtonProps
} from '@chakra-ui/react';
import {
  AbsoluteCenter,
  Button as ChakraButton,
  IconButton as ChakraIconButton,
  Span,
  Spinner
} from '@chakra-ui/react';
import Link from 'next/link';
import * as React from 'react';

interface ButtonLoadingProps {
  loading?: boolean;
  loadingText?: React.ReactNode;
}

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps {}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const { loading, disabled, loadingText, children, ...rest } = props;
  return (
    <ChakraButton disabled={loading || disabled} ref={ref} {...rest}>
      {loading && !loadingText ? (
        <>
          <AbsoluteCenter display="inline-flex">
            <Spinner size="inherit" color="inherit" />
          </AbsoluteCenter>
          <Span opacity={0}>{children}</Span>
        </>
      ) : loading && loadingText ? (
        <>
          <Spinner size="inherit" color="inherit" />
          {loadingText}
        </>
      ) : (
        children
      )}
    </ChakraButton>
  );
});

export const IconButton = React.forwardRef<
  HTMLButtonElement,
  ChakraIconButtonProps & ButtonLoadingProps
>(function IconButton(props, ref) {
  const { loading, disabled, children, ...rest } = props;
  return (
    <ChakraIconButton disabled={loading || disabled} ref={ref} {...rest}>
      {loading ? <Spinner size="inherit" color="inherit" /> : children}
    </ChakraIconButton>
  );
});

export const LinkButton = (
  props: ChakraButtonProps & { href: string; prefetch?: boolean; target?: string }
) => {
  return (
    <ChakraButton size="sm" cursor={'pointer'} {...props} asChild>
      <Link href={props.href} prefetch={props.prefetch} target={props.target}>
        {props.children}
      </Link>
    </ChakraButton>
  );
};
