import { removeNullish, WorkItemTableField } from '../../../types';
import { removeImageTags } from '../../strings';
import { FieldReferenceIdToFieldId, GaebBoqHierarchy } from './types';

export const cleanGaebString = <TText extends string | null>(text: TText): TText => {
  if (!text) {
    return text;
  }
  const textWithoutImageTags = removeImageTags(text);
  return textWithoutImageTags
    .replace(/\.\.\. Freitext \.\.\.*\.\.\. Freitext \.\.\./g, '')
    .replace(/\.\.\. Freitext \.\.\.\*/g, '')
    .replace(/\.\.\. Freitext \.\.\./g, '')
    .trim() as TText;
};

export const getFieldReferenceIdToFieldId = ({
  fields
}: {
  fields: { id: string; referenceId: string }[];
}): FieldReferenceIdToFieldId => {
  return fields.reduce((acc, field) => {
    acc[field.referenceId as WorkItemTableField] = field.id;
    return acc;
  }, {} as FieldReferenceIdToFieldId);
};

export const getItemReferenceNumberPart = ({
  boqHierarchies,
  referenceNumber
}: {
  boqHierarchies: GaebBoqHierarchy[];
  referenceNumber: string;
}): {
  part: string;
  index?: string;
} => {
  const hasIndex = boqHierarchies.find(config => config.type === 'index');
  const parts = referenceNumber.split('.');
  return {
    part: (hasIndex ? parts[parts.length - 2]! : parts[parts.length - 1]!).trim(),
    index: hasIndex ? parts[parts.length - 1]!.trim() : undefined
  };
};

export const getCategoryReferenceNumberPart = ({
  boqHierarchies,
  boqHierarchyIndex,
  referenceNumber
}: {
  boqHierarchies: GaebBoqHierarchy[];
  boqHierarchyIndex: number;
  referenceNumber: string;
}): string => {
  const referenceNumberParts = referenceNumber.split('.');
  return removeTrailingDot(referenceNumberParts[boqHierarchyIndex] ?? '');
};

export const removeTrailingDot = (text: string): string => {
  return text.endsWith('.') ? text.slice(0, -1) : text;
};

export const createReferenceNumber = ({
  boqHierarchies,
  categoryParts,
  itemPart,
  itemIndex,
  useLeadingZeroes = false
}: {
  boqHierarchies: GaebBoqHierarchy[];
  categoryParts: string[];
  itemPart?: string;
  itemIndex?: string;
  useLeadingZeroes?: boolean;
}): string => {
  const isCategory = itemPart === undefined;
  const referenceNumberParts = boqHierarchies
    .map((referenceNumberConfigItem, index) => {
      let part = '';
      if (referenceNumberConfigItem.type === 'category') {
        if (index < categoryParts.length) {
          part = categoryParts[index]!;
        }
      }

      if (isCategory && part === '') {
        return null;
      }

      if (referenceNumberConfigItem.type === 'item' && itemPart) {
        part = itemPart;
      }

      if (referenceNumberConfigItem.type === 'index' && itemIndex) {
        part = itemIndex;
      }
      part = part === '0' ? part : removeLeadingZeroes(part, useLeadingZeroes);
      const spacesToAdd = referenceNumberConfigItem.length - part.length;
      const leadingChars = useLeadingZeroes ? '0' : ' ';
      return leadingChars.repeat(Math.max(0, spacesToAdd)) + part;
    })
    .filter(removeNullish);

  return referenceNumberParts.join('.');
};

export const removeLeadingZeroes = (text: string, useLeadingZeroes: boolean): string => {
  if (text === '0') {
    return '0';
  }
  if (useLeadingZeroes) {
    return text.replace(/^0+/, '');
  }
  return text;
};
