import { typeidUnboxed } from 'typeid-js';
import { reservedFieldIds, storedReservedFieldIds } from '../../types';

export const fieldId = () => typeidUnboxed('field');

export const isReservedFieldId = (
  fieldId: string
): fieldId is (typeof reservedFieldIds)[number] => {
  return reservedFieldIds.includes(fieldId as (typeof reservedFieldIds)[number]);
};

export const isStoredReservedFieldId = (
  fieldId: string
): fieldId is (typeof storedReservedFieldIds)[number] => {
  return storedReservedFieldIds.includes(fieldId as (typeof storedReservedFieldIds)[number]);
};

export const mapFieldReferenceIdToId = <TField extends { id: string; referenceId: string }>(
  fields: TField[]
): Record<string, string> => {
  const fieldMapping = fields.reduce<Record<string, string>>((acc, field) => {
    acc[field.referenceId] = field.id;
    return acc;
  }, {});

  return {
    ...fieldMapping,
    ...(Object.fromEntries(storedReservedFieldIds.map(id => [id, id])) as Record<string, string>)
  };
};

export const mapFieldIdToReferenceId = <TField extends { id: string; referenceId: string }>(
  fields: TField[]
): Record<string, string> => {
  const fieldMapping = fields.reduce<Record<string, string>>((acc, field) => {
    acc[field.id] = field.referenceId;
    return acc;
  }, {});

  return {
    ...fieldMapping,
    ...(Object.fromEntries(storedReservedFieldIds.map(id => [id, id])) as Record<string, string>)
  };
};

export const mapFieldsOfTables = ({
  sourceTableFields,
  destinationTableFields
}: {
  sourceTableFields: { id: string; referenceId: string }[];
  destinationTableFields: { id: string; referenceId: string }[];
}) => {
  const sourceTableFieldReferenceIdToId = mapFieldReferenceIdToId(sourceTableFields);
  const sourceTableFieldIdToReferenceId = mapFieldIdToReferenceId(sourceTableFields);

  const destinationTableFieldReferenceIdToId = mapFieldReferenceIdToId(destinationTableFields);
  const destinationTableFieldIdToReferenceId = mapFieldIdToReferenceId(destinationTableFields);

  const fieldIdMappingDestinationToSource: Record<string, string> = Object.fromEntries(
    Object.entries(destinationTableFieldReferenceIdToId)
      .map(([referenceId, id]) => [id, sourceTableFieldReferenceIdToId[referenceId]])
      .filter(([_, id]) => id !== undefined)
  );

  storedReservedFieldIds.forEach(id => {
    fieldIdMappingDestinationToSource[id] = id;
  });

  return {
    fieldMappings: Object.entries(fieldIdMappingDestinationToSource).map(
      ([destinationFieldId, sourceFieldId]) => ({
        destinationFieldId,
        sourceFieldId
      })
    ),
    fieldIdMappingSourceToDestination: Object.fromEntries(
      Object.entries(fieldIdMappingDestinationToSource).map(
        ([destinationFieldId, sourceFieldId]) => [sourceFieldId, destinationFieldId]
      )
    ),
    fieldIdMappingDestinationToSource,
    destinationTableFieldReferenceIdToId,
    destinationTableFieldIdToReferenceId,
    sourceTableFieldReferenceIdToId,
    sourceTableFieldIdToReferenceId
  };
};
