import { z } from 'zod';
import { addToKnowledgeLibrarySchema } from '../knowledge';
import { pieceOfInformationTypeSchema } from './dto';

export const createPieceOfInformationDtoSchema = z.object({
  type: pieceOfInformationTypeSchema,
  skipProcessing: z.boolean().optional(),
  name: z.string(),
  knowledgeLibrary: addToKnowledgeLibrarySchema.nullable(),
  organizationId: z.string().optional(),
  itemId: z.string(),
  createdById: z.string().optional()
});
export type UpsertPieceOfInformationDto = z.infer<typeof createPieceOfInformationDtoSchema>;
