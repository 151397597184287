export const labelIdSeparator = '|';

export type MentionType = 'conversationMember' | 'pieceOfInformation' | 'task';

const mentionTypePrefixes: Record<MentionType, string> = {
  conversationMember: '@',
  pieceOfInformation: '#',
  task: '!'
};

const reverseMentionTypePrefixes: Record<string, MentionType> = {
  '@': 'conversationMember',
  '#': 'pieceOfInformation',
  '!': 'task'
};

export const formatMention = ({
  type,
  id,
  label
}: {
  type: MentionType;
  id: string;
  label: string;
}) => `${mentionTypePrefixes[type]}[${label}${labelIdSeparator}${id}]`;

export const parseMention = (mention: string) => {
  const prefix = mention[0]!;
  const type = reverseMentionTypePrefixes[prefix];
  const [label, id] = mention.slice(2, -1).split(labelIdSeparator);

  return { type, prefix, id: id!, label: label! };
};

export const isMention = (text: string) => {
  return text.match(/^[#!@]\[[^\]]*\]$/) !== null;
};

export const splitTextByMentions = (text: string): string[] => {
  return text.split(/([@#!]\[[^\]]+\|[^\]]+\])/g);
};

export const getMentions = (text: string) => {
  const mentions = text.match(/@\[[^\]]+\|[^\]]+\]|#[^\]]+\|[^\]]+\]|![^\]]+\|[^\]]+\]/g) || [];
  return mentions.map(mention => parseMention(mention));
};
