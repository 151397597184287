import { z } from 'zod';

export const addressSchema = z.object(
  {
    streetNumber: z.string().nullish(),
    street: z.string().nullish(),
    city: z.string().nullish(),
    zipCode: z.string().nullish(),
    state: z.string().nullish(),
    country: z.string().nullish()
  },
  {
    errorMap: () => {
      return { message: 'Please provide a valid address' };
    }
  }
);

export type Address = z.infer<typeof addressSchema>;
