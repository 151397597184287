import { Address, removeNullish } from '../../types';

export const addressToString = (address: Address): string => {
  const street = address.street ? `${address.street} ${address.streetNumber}` : '';
  const parts = [street, address.zipCode, address.city, address.country];
  return parts
    .filter(removeNullish)
    .filter(part => part !== '')
    .join(', ');
};
