import { z } from 'zod';

export enum FileTagName {
  I_TWO_PROJECT_X83_FILE = 'i-two-project-x83-file',
  I_TWO_PROJECT_XML_FILE = 'i-two-project-xml-file'
}

export enum TableTagName {
  I_TWO_PROJECT_TABLE = 'i-two-project-table',
  I_TWO_COST_ESTIMATOR_WORK_ITEM_RECOMMENDATION_MEMORY = 'i-two-cost-estimator-work-item-recommendation-memory',
  SALES_ACCOUNTS_TABLE = 'sales-accounts-table',
  TENDER_ADMINISTRATOR_AWARD_PACKAGE_MEMORY = 'tender-administrator-award-package-memory',
  CONSTRUCTION_I_TWO_TEMPLATE_TABLE = 'construction-i-two-template-table',
  CONSTRUCTION_SUB_CONTRACTOR_WORK_DESCRIPTION = 'construction-sub-contractor-work-description',
  CONSTRUCTION_SUB_CONTRACTOR_DEFINITION = 'construction-sub-contractor-definition'
}

export enum DocumentTagName {}

export enum EmailDraftTagName {
  CONSTRUCTION_SUB_CONTRACTOR_AWARD_EMAIL_DRAFT = 'construction-sub-contractor-award-email-draft'
}

export const fileTagNameSchema = z.enum(['i-two-project-x83-file', 'i-two-project-xml-file']);
export const tableTagNameSchema = z.enum(['i-two-project-table']);
export const pieceOfInformationTagNameSchema = z.union([fileTagNameSchema, tableTagNameSchema]);

export type PieceOfInformationTagName = z.infer<typeof pieceOfInformationTagNameSchema>;

export const tagScopeSchema = z.enum(['PROJECT', 'ORGANIZATION', 'CONVERSATION']);
export type TagScope = z.infer<typeof tagScopeSchema>;
