import { z } from 'zod';

export const getSimilarRowsSchema = z.object({
  table: z.object({
    id: z.string(),
    rowIds: z.array(z.string())
  }),
  toJoinTable: z.object({
    id: z.string(),
    rowIds: z.array(z.string())
  }),
  fields: z.array(
    z.union([
      z.object({
        referenceId: z.string(),
        weight: z.number()
      }),
      z.object({
        fieldId: z.string(),
        toJoinFieldId: z.string(),
        weight: z.number()
      })
    ])
  ),
  minSimilarity: z.number().optional(),
  options: z
    .object({
      onlyLinkLeafRows: z.boolean().optional(),
      topN: z.number(),
      emptyValueSimilarityFallback: z.number().optional(),
      withoutDuplicates: z.boolean().optional()
    })
    .optional()
});
export type GetSimilarRowsArgs = z.infer<typeof getSimilarRowsSchema>;

export const getSimilarRowsOutputWithNullsSchema = z.array(
  z.object({
    rowId: z.string(),
    similarRows: z.array(
      z.object({
        rowId: z.string().nullable(),
        similarity: z.number().nullable()
      })
    )
  })
);
export type GetSimilarRowsOutputWithNulls = z.infer<typeof getSimilarRowsOutputWithNullsSchema>;

export const getSimilarRowsOutputSchema = z.array(
  z.object({
    rowId: z.string(),
    similarRows: z.array(
      z.object({
        rowId: z.string(),
        similarity: z.number()
      })
    )
  })
);
export type GetSimilarRowsOutput = z.infer<typeof getSimilarRowsOutputSchema>;
