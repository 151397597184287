import { z } from 'zod';

export const tableFilterTypeSchema = z.enum([
  'STRING',
  'STRING_ARRAY',
  'DATE',
  'NUMBER',
  'BOOLEAN',
  'JSONB'
]);
export type TableFilterType = z.infer<typeof tableFilterTypeSchema>;

export const booleanFilterOperatorSchema = z.enum(['IS_TRUE', 'IS_FALSE']);
export type BooleanFilterOperator = z.infer<typeof booleanFilterOperatorSchema>;

export const stringFilterOperatorSchema = z.enum([
  'CONTAINS',
  'NOT_CONTAINS',
  'EQUALS',
  'NOT_EQUALS',
  'STARTS_WITH',
  'ENDS_WITH',
  'EMPTY',
  'NOT_EMPTY',
  'IN',
  'NOT_IN'
]);
export type StringFilterOperator = z.infer<typeof stringFilterOperatorSchema>;

export const numberFilterOperatorSchema = z.enum([
  'EMPTY',
  'NOT_EMPTY',
  'EQUALS',
  'NOT_EQUALS',
  'LESS_THAN',
  'GREATER_THAN',
  'GREATER_THAN_OR_EQUALS',
  'LESS_THAN_OR_EQUALS'
]);
export type NumberFilterOperator = z.infer<typeof numberFilterOperatorSchema>;

export const dateFilterOperatorSchema = z.enum([
  'EMPTY',
  'NOT_EMPTY',
  'EQUALS',
  'NOT_EQUALS',
  'AFTER',
  'BEFORE'
]);
export type DateFilterOperator = z.infer<typeof dateFilterOperatorSchema>;

export const stringArrayFilterOperatorSchema = z.enum([
  'CONTAINS',
  'NOT_CONTAINS',
  'EQUALS',
  'NOT_EQUALS'
]);
export type StringArrayFilterOperator = z.infer<typeof stringArrayFilterOperatorSchema>;

export const jsonbFilterOperatorSchema = z.enum([
  'CONTAINS',
  'NOT_CONTAINS',
  'IS_NULL',
  'IS_NOT_NULL'
]);
export type JsonbFilterOperator = z.infer<typeof jsonbFilterOperatorSchema>;

export const tableFilterSchema = z.discriminatedUnion('type', [
  z.object({
    id: z.string(),
    fieldId: z.string(),
    type: z.literal('BOOLEAN'),
    operator: booleanFilterOperatorSchema,
    value: z.boolean().nullish()
  }),
  z.object({
    id: z.string().optional(),
    fieldId: z.string(),
    type: z.literal('STRING'),
    operator: stringFilterOperatorSchema,
    value: z.union([z.string(), z.array(z.string())]).nullish()
  }),
  z.object({
    id: z.string(),
    fieldId: z.string(),
    type: z.literal('STRING_ARRAY'),
    operator: stringArrayFilterOperatorSchema,
    value: z.array(z.string()).nullish()
  }),
  z.object({
    id: z.string(),
    fieldId: z.string(),
    type: z.literal('NUMBER'),
    operator: numberFilterOperatorSchema,
    value: z.number().nullish()
  }),
  z.object({
    id: z.string(),
    fieldId: z.string(),
    type: z.literal('DATE'),
    operator: dateFilterOperatorSchema,
    value: z.date().nullish()
  }),
  z.object({
    id: z.string(),
    fieldId: z.string(),
    type: z.literal('JSONB'),
    operator: jsonbFilterOperatorSchema,
    value: z.string().nullish()
  })
]);
export type TableFilter = z.infer<typeof tableFilterSchema>;

export const tableFilterGroupTypeSchema = z.enum(['AND', 'OR']);
export type TableFilterGroupType = z.infer<typeof tableFilterGroupTypeSchema>;

export const tableFilterGroupSchema: z.ZodType<{
  type: TableFilterGroupType;
  filters: (TableFilter | TableFilterGroup)[];
}> = z.object({
  type: tableFilterGroupTypeSchema,
  filters: z.array(z.union([tableFilterSchema, z.lazy(() => tableFilterGroupSchema)]))
});
export type TableFilterGroup = z.infer<typeof tableFilterGroupSchema>;
