import { I18n } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { err, ok, Result } from 'neverthrow';
import { typeidUnboxed } from 'typeid-js';
import { xml2json } from 'xml-js';
import { getFileNameWithoutExtension, getTypedGaebFile } from '../..';
import {
  COST_CALCULATION_TABLE_FIELDS,
  CostCalculationTableField,
  CreateTable,
  removeNullish,
  TableRowPrimitiveDataType,
  UpsertTableRow,
  WORK_ITEM_TABLE_FIELDS,
  WorkItemTableField,
  WorkItemTableTypeFieldSelectOptionId
} from '../../../types';
import { standardizeUnit } from '../../unit';
import { GAEB83, GaebBoqCategory, GaebBoqItem } from '../gaeb/types';
import { cleanGaebString } from '../gaeb/utils';
import { XmlDocument, XmlElement } from '../xml-converter';
import { getCreateTableConfigForITwoProject } from './table-config';
import {
  WBSItem,
  WBSItemEstDetailsAssemblyDetail,
  WBSItemEstDetailsCoCDetail,
  WBSItemEstDetailsCommodityDetail,
  WBSItemEstDetailsEstCalcElement,
  WBSItemEstDetailsEstTextElement,
  WBSItemEstDetailsItem,
  WBSItemEstDetailsSubItem
} from './types/cost-estimation';
import { CostEstimationXml } from './types/cost-estimation-xml';
import { getItemsFromWbs, getItwoMajorWbsMapFromXml } from './utils';

export const getTypedItwoCostCalculation = ({
  iTwoCalculationXml
}: {
  iTwoCalculationXml: string;
}): CostEstimationXml => {
  const costCalculationJson: CostEstimationXml = JSON.parse(
    xml2json(iTwoCalculationXml, { compact: true, spaces: 2 })
  );
  return costCalculationJson;
};

type Error = {
  type: 'REFERENCE_NUMBERS_DO_NOT_MATCH' | 'GAEB_PARSING_ERROR' | 'BOQ_HAVE_SAME_NAME';
  message: string;
};

export const getCreateTableConfigFromITwoFiles = (
  {
    tableName,
    iTwoCalculationFile,
    gaeb83Files,
    projectId
  }: {
    tableName: string;
    iTwoCalculationFile: { id: string; name: string; extension: string; content: string } | null;
    gaeb83Files: { id: string; name: string; extension: string; content: string }[];
    projectId: string | null;
  },
  i18n: I18n
): Result<CreateTable & { warningMessage?: string | null }, Error> => {
  // WORK ITEMS
  const fieldReferenceIdToId = Object.values(WORK_ITEM_TABLE_FIELDS).reduce(
    (acc, field) => {
      acc[field.id] = typeidUnboxed('field');
      return acc;
    },
    {} as Record<WorkItemTableField, string>
  );

  const gaebResults = gaeb83Files.map(gaeb83File =>
    getTypedGaebFile({ gaeb83Xml: gaeb83File.content })
  );

  if (gaebResults.some(gaebResult => gaebResult.isErr())) {
    return err({
      type: 'GAEB_PARSING_ERROR',
      message: i18n._(
        msg`Error parsing GAEB files. Please check the input files. Make sure that the structure of the GAEB files is correct.`
      )
    });
  }

  const gaebs = gaebResults
    .map(gaebResult => (gaebResult.isOk() ? gaebResult.value : null))
    .filter(removeNullish);
  const boqNames = gaebs.map(
    (gaeb, index) =>
      gaeb.award.boq.info.name ?? getFileNameWithoutExtension(gaeb83Files[index]!.name)
  );
  const boqNamesSet = new Set(boqNames);
  if (boqNamesSet.size !== boqNames.length) {
    return err({
      type: 'BOQ_HAVE_SAME_NAME',
      message: i18n._(
        msg`The BOQ name "${boqNames.find(name => boqNames.filter(n => n === name).length > 1) ?? ''}" is used for multiple GAEB files. Please make sure that you exported the BOQ's from the same iTWO project variant, since the BOQ name needs to be unique.`
      )
    });
  }

  const boQNameToBoqDetails = gaebs.reduce(
    (acc, gaeb, index) => {
      const boqName =
        gaeb.award.boq.info.name ?? getFileNameWithoutExtension(gaeb83Files[index]!.name);

      acc[boqName] = {
        boqName,
        labelBoq: gaeb.award.boq.info.label ?? null,
        rows: convertGaebFileToTableRows({
          gaeb,
          fieldReferenceIdToId,
          boqName,
          projectId
        })
      };

      return acc;
    },
    {} as Record<
      string,
      {
        boqName: string | null;
        labelBoq: string | null;
        rows: UpsertTableRow[];
      }
    >
  );

  const boqNameAndReferenceNumberToTableRowId = Object.entries(boQNameToBoqDetails).reduce(
    (acc, [boqName, { rows }]) => {
      rows.forEach(row => {
        if (row[fieldReferenceIdToId.type] === WorkItemTableTypeFieldSelectOptionId.WorkItem) {
          const referenceNumber = row[fieldReferenceIdToId.referenceNumber];

          if (referenceNumber) {
            const key = `${boqName}-${referenceNumber.toString()}`;
            acc[key] = row.id;
          }
        }
      });
      return acc;
    },
    {} as Record<string, string>
  );

  // COST CALCULATION
  const costCalculationJson: XmlDocument | null = iTwoCalculationFile
    ? JSON.parse(xml2json(iTwoCalculationFile.content, { compact: false, spaces: 2 }))
    : null;
  const costCalculationTableResult = costCalculationJson
    ? getCostCalculationTable(
        {
          majorWbsMap: getItwoMajorWbsMapFromXml(costCalculationJson),
          boqNameAndReferenceNumberToTableRowId,
          projectId
        },
        i18n
      )
    : ok({
        rows: [],
        fieldReferenceIdToId: undefined
      });

  if (costCalculationTableResult.isErr()) {
    return err(costCalculationTableResult.error);
  }

  return ok(
    getCreateTableConfigForITwoProject(
      {
        projectId,
        boqTable: {
          fieldReferenceIdToId,
          rows: Object.values(boQNameToBoqDetails).flatMap(({ rows }) => rows),
          tableName
        },
        costCalculationTable: {
          fieldReferenceIdToId: costCalculationTableResult.value.fieldReferenceIdToId,
          rows: costCalculationTableResult.value.rows
        }
      },
      i18n
    )
  );
};

const getCostCalculationTable = (
  {
    majorWbsMap,
    boqNameAndReferenceNumberToTableRowId,
    projectId
  }: {
    majorWbsMap: Record<string, XmlElement>;
    boqNameAndReferenceNumberToTableRowId: Record<string, string>;
    projectId: string | null;
  },
  i18n: I18n
): Result<
  {
    rows: UpsertTableRow[];
    fieldReferenceIdToId: Record<string, string>;
  },
  Error
> => {
  const fieldReferenceIdToId = Object.values(COST_CALCULATION_TABLE_FIELDS).reduce(
    (acc, field) => {
      acc[field.id] = typeidUnboxed('field');
      return acc;
    },
    {} as Record<CostCalculationTableField, string>
  );

  let missingReferenceNumberCount = Object.keys(boqNameAndReferenceNumberToTableRowId).length;

  const rows = Object.entries(majorWbsMap)
    .flatMap(([wbsName, wbs]) => {
      const { items } = getItemsFromWbs(wbs);
      if (items.length === 0) {
        return [];
      }
      const wbsItems = items.filter((item): item is WBSItem => item.type === 'WorkItem');
      return wbsItems.map(item => ({ ...item, wbsName }));
    })
    .flatMap(({ wbsName, ...item }) => {
      const wbsItemReferenceNumber = item.name!;
      const boqName = wbsName;
      const key = `${boqName}-${wbsItemReferenceNumber}`;
      const parentTableRowId = boqNameAndReferenceNumberToTableRowId[key];

      if (parentTableRowId) {
        missingReferenceNumberCount--;
        return convertWbsItemToRows({
          wbsItem: item,
          parentTableRowId
        });
      } else {
        return [];
      }
    })
    .map(({ id, parentRowId, parentTableRowId, ...row }) => {
      return {
        ...Object.fromEntries(
          Object.entries(row).map(([key, value]) => [
            fieldReferenceIdToId[key as CostCalculationTableField],
            value
          ])
        ),
        id,
        parentRowId: parentRowId ?? null,
        parentTableRowId: parentTableRowId ?? null,
        projectId
      };
    });

  if (missingReferenceNumberCount > 0) {
    return err({
      type: 'REFERENCE_NUMBERS_DO_NOT_MATCH',
      message: i18n._(
        msg`I could not find a cost calculation for ${missingReferenceNumberCount} work item(s). Please check the input files. Make sure that the structure of the reference numbers is the same (leading vs non leading zeros).`
      )
    });
  }

  return ok({
    rows,
    fieldReferenceIdToId
  });
};

type CostCalculationUpsertTableRow = UpsertTableRow &
  Record<CostCalculationTableField, TableRowPrimitiveDataType>;

const convertWbsItemToRows = ({
  wbsItem,
  parentTableRowId
}: {
  wbsItem: WBSItem;
  parentTableRowId: string;
}): CostCalculationUpsertTableRow[] => {
  const convertCoCDetail = (
    cocDetail: WBSItemEstDetailsCoCDetail,
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow => {
    return {
      id: typeidUnboxed('row'),
      parentRowId: parent.rowId,
      parentTableRowId,
      type: 'CoCDetail',
      key: cocDetail.key ?? null,
      name: cleanGaebString(cocDetail.name ?? null),
      identifyKey: cocDetail.identifyKey ?? null,
      isDisabled: cocDetail.isDisabled ?? null,
      currency: cocDetail.currency ?? null,
      quantity: cocDetail.quantity ?? null,
      factor: cocDetail.factor ?? null,
      factorIsPerformanceFactor: cocDetail.factorIsPerformanceFactor ?? null,
      costPerUnit: cocDetail.urValue ?? null,
      costFactor: cocDetail.costFactor ?? null,
      cFactorCoc: cocDetail.cFactorCoc ?? null,
      qFactorCoc: cocDetail.qFactorCoc ?? null,
      flagFixedBudget: cocDetail.flagFixedBudget ?? null,
      budgetUomItem: cocDetail.budgetUomItem ?? null,
      budget: cocDetail.budget ?? null,
      unit: parent.item?.type === 'SubItem' ? standardizeUnit(parent.item?.unitOfMeasure) : null,
      otherXmlFieldsAsJson: cocDetail.otherXmlFieldsAsJson ?? null,
      // SubItem fields
      compressed: null,
      referenceNumber: null,
      sItemLSum: null,
      sItemLSumAbs: null,
      sItemReserve: null,
      spPhase: null,
      sItemNo: null
    };
  };

  const convertCommodityDetail = (
    commodityDetail: WBSItemEstDetailsCommodityDetail,
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    const rowId = typeidUnboxed('row');

    return [
      {
        id: rowId,
        parentRowId: parent.rowId,
        parentTableRowId,
        type: 'CommodityDetail',
        key: commodityDetail.nameCommodity ?? null,
        name: cleanGaebString(commodityDetail.descrCommodity ?? null),
        budgetUomItem: commodityDetail.budgetUomItem ?? null,
        budget: commodityDetail.budget ?? null,
        quantity: commodityDetail.quantity ?? null,
        factor: commodityDetail.factor ?? null,
        factorIsPerformanceFactor: commodityDetail.factorIsPerformanceFactor ?? null,
        costFactor: commodityDetail.costFactor ?? null,
        costPerUnit: commodityDetail.urValue ?? null,
        currency: commodityDetail.currency ?? null,
        identifyKey: commodityDetail.identifyKey ?? null,
        isDisabled: commodityDetail.isDisabled ?? null,
        flagFixedBudget: commodityDetail.flagFixedBudget ?? null,
        otherXmlFieldsAsJson: commodityDetail.otherXmlFieldsAsJson ?? null,
        cFactorCoc: null,
        qFactorCoc: null,
        unit: null,
        compressed: null,
        referenceNumber: null,
        sItemLSum: null,
        sItemLSumAbs: null,
        sItemReserve: null,
        spPhase: null,
        sItemNo: null
      },
      ...(commodityDetail.estDetails?.items
        ? convertToRows(
            commodityDetail.estDetails?.items,
            {
              item: {
                ...commodityDetail,
                type: 'CommodityDetail' as const
              },
              rowId
            },
            parentTableRowId
          )
        : [])
    ];
  };

  const convertSubItem = (
    subItem: WBSItemEstDetailsSubItem,
    parent: {
      item: WBSItemEstDetailsSubItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    const rowId = typeidUnboxed('row');
    return [
      {
        id: rowId,
        parentRowId: parent.rowId,
        parentTableRowId,
        type: 'SubItem',
        quantity: subItem.quantity ?? null,
        factor: subItem.factor ?? null,
        factorIsPerformanceFactor: subItem.factorIsPerformanceFactor ?? null,
        costFactor: subItem.costFactor ?? null,
        flagFixedBudget: subItem.flagFixedBudget ?? null,
        budgetUomItem: subItem.budgetUomItem ?? null,
        budget: subItem.budget ?? null,
        name: cleanGaebString(subItem.text ?? null),
        unit: standardizeUnit(subItem.unitOfMeasure),
        sItemNo: subItem.sItemNo ?? null,
        sItemLSum: subItem.sItemLSum ?? null,
        sItemLSumAbs: subItem.sItemLSumAbs ?? null,
        isDisabled: subItem.sItemDisabled ?? null,
        compressed: subItem.compressed ?? null,
        sItemReserve: subItem.sItemReserve ?? null,
        spPhase: subItem.spPhase ?? null,
        referenceNumber: subItem.subItemNumber ?? null,
        otherXmlFieldsAsJson: subItem.otherXmlFieldsAsJson ?? null,
        // CoCDetail fields
        key: null,
        identifyKey: null,
        currency: null,
        cFactorCoc: null,
        costPerUnit: null,
        qFactorCoc: null
      },
      ...(subItem.estDetails?.items
        ? convertToRows(
            subItem.estDetails?.items,
            {
              item: {
                ...subItem,
                type: 'SubItem' as const
              },
              rowId
            },
            parentTableRowId
          )
        : [])
    ];
  };

  const convertEstTextElement = (
    estTextElement: WBSItemEstDetailsEstTextElement,
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    return [
      {
        id: typeidUnboxed('row'),
        parentRowId: parent.rowId,
        parentTableRowId,
        type: 'EstTextElement',
        name: cleanGaebString(estTextElement.text ?? null),
        otherXmlFieldsAsJson: estTextElement.otherXmlFieldsAsJson ?? null,
        budget: null,
        budgetUomItem: null,
        costFactor: null,
        costPerUnit: null,
        currency: null,
        flagFixedBudget: null,
        identifyKey: null,
        isDisabled: null,
        key: null,
        cFactorCoc: null,
        qFactorCoc: null,
        unit: null,
        compressed: null,
        referenceNumber: null,
        sItemLSum: null,
        sItemLSumAbs: null,
        sItemReserve: null,
        spPhase: null,
        sItemNo: null,
        factor: null,
        factorIsPerformanceFactor: null,
        quantity: null
      }
    ];
  };

  const convertAssemblyDetail = (
    assemblyDetail: WBSItemEstDetailsAssemblyDetail,
    parent: {
      item: WBSItemEstDetailsAssemblyDetail | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    const rowId = typeidUnboxed('row');
    return [
      {
        id: rowId,
        parentRowId: parent.rowId,
        parentTableRowId,
        type: 'AssemblyDetail',
        key: assemblyDetail.nameAssembly ?? null,
        name: cleanGaebString(assemblyDetail.descrAssembly ?? null),
        unit: assemblyDetail.unitOfMeasure ?? null,
        quantity: assemblyDetail.quantity ?? null,
        factor: assemblyDetail.factor ?? null,
        factorIsPerformanceFactor: assemblyDetail.factorIsPerformanceFactor ?? null,
        costFactor: assemblyDetail.costFactor ?? null,
        costPerUnit: null,
        currency: null,
        identifyKey: assemblyDetail.identifyKey ?? null,
        isDisabled: assemblyDetail.isDisabled ?? null,
        flagFixedBudget: assemblyDetail.flagFixedBudget ?? null,
        otherXmlFieldsAsJson: assemblyDetail.otherXmlFieldsAsJson ?? null,
        budget: assemblyDetail.budget ?? null,
        budgetUomItem: assemblyDetail.budgetUomItem ?? null,
        cFactorCoc: null,
        qFactorCoc: null,
        compressed: null,
        referenceNumber: null,
        sItemLSum: null,
        sItemLSumAbs: null,
        sItemReserve: null,
        spPhase: null,
        sItemNo: null
      },
      ...(assemblyDetail.estDetails?.items
        ? convertToRows(
            assemblyDetail.estDetails?.items,
            {
              item: {
                ...assemblyDetail,
                type: 'AssemblyDetail' as const
              },
              rowId
            },
            parentTableRowId
          )
        : [])
    ];
  };

  const convertEstCalcElement = (
    estCalcElement: WBSItemEstDetailsEstCalcElement,
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    return [
      {
        id: typeidUnboxed('row'),
        parentRowId: parent.rowId,
        parentTableRowId,
        type: 'EstCalcElement',
        name: estCalcElement.formula ?? null,
        otherXmlFieldsAsJson: estCalcElement.otherXmlFieldsAsJson ?? null,
        budget: null,
        budgetUomItem: null,
        costFactor: null,
        costPerUnit: null,
        currency: null,
        flagFixedBudget: null,
        identifyKey: null,
        isDisabled: null,
        key: null,
        cFactorCoc: null,
        qFactorCoc: null,
        unit: null,
        compressed: null,
        referenceNumber: null,
        sItemLSum: null,
        sItemLSumAbs: null,
        sItemReserve: null,
        spPhase: null,
        sItemNo: null,
        factor: null,
        factorIsPerformanceFactor: null,
        quantity: null
      }
    ];
  };

  const convertToRows = (
    items: WBSItemEstDetailsItem[],
    parent: {
      item: WBSItemEstDetailsItem | null;
      rowId: string | null;
    },
    parentTableRowId: string
  ): CostCalculationUpsertTableRow[] => {
    return items
      .flatMap((item): CostCalculationUpsertTableRow[] => {
        if (item.type === 'CoCDetail') {
          return [
            convertCoCDetail(
              item,
              {
                item: parent.item,
                rowId: parent.rowId!
              },
              parentTableRowId
            )
          ];
        } else if (item.type === 'SubItem') {
          return convertSubItem(
            item,
            {
              item: parent.item as WBSItemEstDetailsSubItem | null,
              rowId: parent.rowId
            },
            parentTableRowId
          );
        } else if (item.type === 'CommodityDetail') {
          return convertCommodityDetail(
            item,
            {
              item: parent.item,
              rowId: parent.rowId
            },
            parentTableRowId
          );
        } else if (item.type === 'AssemblyDetail') {
          return convertAssemblyDetail(
            item,
            {
              item: parent.item as WBSItemEstDetailsAssemblyDetail | null,
              rowId: parent.rowId
            },
            parentTableRowId
          );
        } else if (item.type === 'EstTextElement') {
          return convertEstTextElement(
            item,
            {
              item: parent.item,
              rowId: parent.rowId
            },
            parentTableRowId
          );
        } else if (item.type === 'EstCalcElement') {
          return convertEstCalcElement(
            item,
            {
              item: parent.item,
              rowId: parent.rowId
            },
            parentTableRowId
          );
        } else {
          return [];
        }
      })
      .filter(removeNullish);
  };

  const subItems = wbsItem.estDetails?.items || [];

  if (subItems.length === 0) {
    return [];
  }

  const rows = convertToRows(
    subItems,
    {
      item: null,
      rowId: null
    },
    parentTableRowId
  );

  return rows;
};

const convertGaebFileToTableRows = ({
  gaeb,
  fieldReferenceIdToId,
  boqName,
  projectId
}: {
  gaeb: GAEB83;
  fieldReferenceIdToId: Record<WorkItemTableField, string>;
  boqName: string;
  projectId: string | null;
}): UpsertTableRow[] => {
  const boqNameRow: UpsertTableRow = {
    id: typeidUnboxed('row'),
    parentRowId: null,
    parentTableRowId: null,
    projectId,
    [fieldReferenceIdToId.boqName]: boqName,
    [fieldReferenceIdToId.type]: 'BoQ',
    [fieldReferenceIdToId.referenceNumber]: null,
    [fieldReferenceIdToId.shortText]: boqName,
    [fieldReferenceIdToId.longText]: gaeb.award.boq.info.label ?? null,
    [fieldReferenceIdToId.quantity]: null,
    [fieldReferenceIdToId.unit]: null
  };

  const convertItemOrRemark = (
    items: GaebBoqItem[],
    parent: {
      rowId: string | null;
    }
  ): UpsertTableRow[] => {
    return items.map(item => {
      if (item.type === 'workItem') {
        return {
          id: typeidUnboxed('row'),
          parentRowId: parent.rowId,
          parentTableRowId: null,
          projectId,
          [fieldReferenceIdToId.boqName]: boqName,
          [fieldReferenceIdToId.type]: WorkItemTableTypeFieldSelectOptionId.WorkItem,
          [fieldReferenceIdToId.referenceNumber]: item.referenceNumber,
          [fieldReferenceIdToId.shortText]: item.label,
          [fieldReferenceIdToId.longText]: item.description,
          [fieldReferenceIdToId.quantity]: item.quantity ?? null,
          [fieldReferenceIdToId.unit]: item.unit
        };
      } else {
        return {
          id: typeidUnboxed('row'),
          parentRowId: parent.rowId,
          parentTableRowId: null,
          projectId,
          [fieldReferenceIdToId.boqName]: boqName,
          [fieldReferenceIdToId.type]: WorkItemTableTypeFieldSelectOptionId.Remark,
          [fieldReferenceIdToId.referenceNumber]: null,
          [fieldReferenceIdToId.shortText]: item.label,
          [fieldReferenceIdToId.longText]: item.description,
          [fieldReferenceIdToId.quantity]: null,
          [fieldReferenceIdToId.unit]: null
        };
      }
    });
  };

  const convertCategories = (
    categories: GaebBoqCategory[],
    parent: {
      rowId: string | null;
    }
  ): UpsertTableRow[] => {
    return categories.flatMap((category): UpsertTableRow[] => {
      const categoryRow = {
        id: typeidUnboxed('row'),
        parentRowId: parent.rowId,
        parentTableRowId: null,
        projectId,
        [fieldReferenceIdToId.boqName]: boqName,
        [fieldReferenceIdToId.type]: 'Category',
        [fieldReferenceIdToId.referenceNumber]: category.referenceNumber,
        [fieldReferenceIdToId.shortText]: category.label,
        [fieldReferenceIdToId.longText]: null,
        [fieldReferenceIdToId.quantity]: null,
        [fieldReferenceIdToId.unit]: null
      };

      return [
        categoryRow,
        ...convertCategories(category.categories, {
          rowId: categoryRow.id
        }),
        ...convertItemOrRemark(category.items, {
          rowId: categoryRow.id
        })
      ];
    });
  };

  const categoryRows = convertCategories(gaeb.award.boq.body.categories ?? [], {
    rowId: null
  });

  return [
    boqNameRow,
    ...categoryRows.map(row => ({
      ...row,
      parentRowId: row.parentRowId === null ? boqNameRow.id : (row.parentRowId ?? null)
    }))
  ];
};
