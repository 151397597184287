import { PieceOfInformationDto } from './dto';

export * from './change-proposal';
export * from './dto';
export * from './template';
export * from './upsert';

export type PieceOfInformationProcessingStatus = 'UPLOADING' | 'UPLOADED' | 'ERROR';

export type PieceOfInformationDtoWithStatus = PieceOfInformationDto & {
  status: PieceOfInformationProcessingStatus;
};

export type PieceOfInformationAiProcessingStatus =
  | 'PENDING'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'FAILED'
  | 'SKIPPED';
