import { z } from 'zod';

export const taskTriggerTypeSchema = z.enum(['SCHEDULE', 'EVENT']);
export type TaskTriggerType = z.infer<typeof taskTriggerTypeSchema>;

export const taskTriggerEventTypeSchema = z.enum(['EMAIL_INCOMING']);
export type TaskTriggerEventType = z.infer<typeof taskTriggerEventTypeSchema>;

const emailIncomingEventConfigSchema = z.object({
  type: z.literal('EMAIL_INCOMING'),
  from: z.string().optional(),
  to: z.string(),
  subjectContains: z.string().optional()
});

export const taskTriggerEventConfigSchema = z.discriminatedUnion('type', [
  emailIncomingEventConfigSchema
]);
export type TaskTriggerEventConfig = z.infer<typeof taskTriggerEventConfigSchema>;

export const taskTriggerConfigSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('SCHEDULE'),
    schedule: z.object({
      cronExpression: z.string()
    })
  }),
  z.object({
    type: z.literal('EVENT'),
    event: taskTriggerEventConfigSchema
  })
]);
export type TaskTriggerConfig = z.infer<typeof taskTriggerConfigSchema>;

export type CreateTaskTrigger = {
  type: TaskTriggerType;
  config: TaskTriggerConfig;
  taskId: string;
  teams?: { id: string }[];
  users?: { id: string }[];
  creatorId: string;
};
