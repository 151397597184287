'use client';

import '../styles.css';

import { Box, Flex, Stack } from '@chakra-ui/react';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';

import { CellSelection, TableMap } from 'prosemirror-tables';

import { EditorContent, useEditor } from '@tiptap/react';
import {
  RICH_TEXT_EDITOR_BOLD_CONTROL,
  RICH_TEXT_EDITOR_HEADING_1_CONTROL,
  RICH_TEXT_EDITOR_HEADING_2_CONTROL,
  RICH_TEXT_EDITOR_HEADING_3_CONTROL,
  RICH_TEXT_EDITOR_ITALIC_CONTROL,
  RICH_TEXT_EDITOR_ORDERED_LIST_CONTROL,
  RICH_TEXT_EDITOR_REDO_CONTROL,
  RICH_TEXT_EDITOR_TABLE_CONTROL,
  RICH_TEXT_EDITOR_UNDERLINE_CONTROL,
  RICH_TEXT_EDITOR_UNDO_CONTROL,
  RICH_TEXT_EDITOR_UNORDERED_LIST_CONTROL,
  RichTextEditorToolbar
} from '../toolbar';
import { CustomTextStyle } from '../extensions';
import { TableOperationsMenu } from './table-operations-menu';

interface DocumentEditorProps {
  content: string;
  setContent: (content: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

export const DocumentEditor = ({
  content,
  setContent,
  placeholder,
  autoFocus
}: DocumentEditorProps) => {
  const editor = useEditor(
    {
      immediatelyRender: false,
      autofocus: autoFocus ? 'start' : false,
      editable: true,
      extensions: [
        Document,
        Paragraph,
        Text,
        CustomTextStyle.configure({ mergeNestedSpanStyles: true }),
        Bold,
        HardBreak,
        ListItem,
        Link.configure({
          openOnClick: true,
          autolink: true,
          linkOnPaste: true
        }),
        Heading,
        OrderedList.configure({
          keepAttributes: true,
          keepMarks: true
        }),
        BulletList,
        Blockquote,
        Bold,
        Italic,
        Strike,
        Underline,
        Placeholder.configure({ placeholder }),
        History,
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell
      ],
      content,
      onUpdate: ({ editor }) => {
        setContent(editor.getHTML());
      }
    },
    []
  );

  const onContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!editor) return;

    const target = event.target as HTMLElement;
    const cell = target.closest('td, th');
    if (!cell) return;

    // Get the cell's document position.
    const cellPos = editor.view.posAtDOM(cell, 0);
    if (cellPos == null) return;

    const $cell = editor.state.doc.resolve(cellPos);
    let table;
    let tablePos = 0;
    for (let depth = $cell.depth; depth > 0; depth--) {
      const node = $cell.node(depth);
      if (node.type.spec.tableRole === 'table') {
        table = node;
        tablePos = $cell.start(depth);
        break;
      }
    }
    if (!table) return;

    const map = TableMap.get(table);

    // Use nodeDOM to match the clicked cell with the mapped cells.
    const cellIndex = map.map.findIndex(offset => {
      const pos = tablePos + offset;
      const dom = editor.view.nodeDOM(pos);
      return dom === cell;
    });

    if (cellIndex < 0) return;

    const row = Math.floor(cellIndex / map.width);
    const col = cellIndex % map.width;

    let selection;
    if (cell.tagName.toLowerCase() === 'th') {
      // For header cells, select the entire column.
      const cells = map.cellsInRect({
        left: col,
        top: 0,
        right: col + 1,
        bottom: map.height
      });
      if (!cells.length) return;
      const anchor = tablePos + cells[0]!;
      const head = tablePos + cells[cells.length - 1]!;
      selection = CellSelection.create(editor.state.doc, anchor, head);
    } else if (cell.tagName.toLowerCase() === 'td') {
      // For body cells, select the entire row.
      const cells = map.cellsInRect({
        left: 0,
        top: row,
        right: map.width,
        bottom: row + 1
      });
      if (!cells.length) return;
      const anchor = tablePos + cells[0]!;
      const head = tablePos + cells[cells.length - 1]!;
      selection = CellSelection.create(editor.state.doc, anchor, head);
    }

    if (selection) {
      const tr = editor.state.tr.setSelection(selection);
      editor.view.dispatch(tr);
      editor.commands.focus();
    }
  };

  return (
    <Stack w={'full'} h={'full'} gap={0}>
      <RichTextEditorToolbar
        editor={editor}
        styles={{ px: 6 }}
        toolbar={{
          controlsGroups: [
            {
              controls: [
                RICH_TEXT_EDITOR_BOLD_CONTROL,
                RICH_TEXT_EDITOR_ITALIC_CONTROL,
                RICH_TEXT_EDITOR_UNDERLINE_CONTROL
              ]
            },
            {
              controls: [
                RICH_TEXT_EDITOR_HEADING_1_CONTROL,
                RICH_TEXT_EDITOR_HEADING_2_CONTROL,
                RICH_TEXT_EDITOR_HEADING_3_CONTROL
              ]
            },
            {
              controls: [
                RICH_TEXT_EDITOR_ORDERED_LIST_CONTROL,
                RICH_TEXT_EDITOR_UNORDERED_LIST_CONTROL,
                RICH_TEXT_EDITOR_TABLE_CONTROL
              ]
            },
            {
              controls: [RICH_TEXT_EDITOR_REDO_CONTROL, RICH_TEXT_EDITOR_UNDO_CONTROL]
            }
          ]
        }}
      />
      <Flex
        w={'full'}
        flexGrow={1}
        flexShrink={1}
        flexBasis={0}
        overflow={'auto'}
        justify={'flex-start'}
        px={3}
      >
        <Box position="relative" w="full" maxW="880px">
          <EditorContent
            editor={editor}
            onClick={() => editor?.commands.focus()}
            onContextMenu={onContextMenu}
            style={{
              height: '100%',
              textAlign: 'left'
            }}
          />
        </Box>
      </Flex>
      <TableOperationsMenu editor={editor} />
    </Stack>
  );
};
