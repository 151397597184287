import TurndownService, { Rule } from 'turndown';
import { js2xml, xml2js } from 'xml-js';
import { formatMention } from '../mentions';

export const convertJsonToHtml = (json: Record<string, unknown>) => {
  const xmlString = js2xml(json, { compact: true, spaces: 4 });
  return xmlString;
};

export const removeXmlTags = (html: string) => {
  return html
    .replace(/<br\s*\/?>/gi, ' ')
    .replace(/<[^>]*>?/g, '')
    .trim();
};

export const removeImageTags = (html: string) =>
  html.replace(/<(?:img\b[^>]*\/?>|image\b[^>]*>[\s\S]*?<\/image>)/gi, '');

const htmlTags = [
  'a',
  'abbr',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'base',
  'bdi',
  'bdo',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'data',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  'embed',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hr',
  'html',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'meta',
  'meter',
  'nav',
  'noscript',
  'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'picture',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'script',
  'section',
  'select',
  'small',
  'source',
  'span',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'svg',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  'var',
  'video',
  'wbr'
];

export const removeNonHtmlTags = (xmlString: string) => {
  const result = xml2js(xmlString, { compact: false });

  function filterHtmlElements<TNode extends { type: string; name: string; elements: TNode[] }>(
    node: TNode
  ) {
    if (node.type === 'element') {
      if (htmlTags.includes(node.name.toLowerCase())) {
        if (node.elements) {
          node.elements = node.elements.filter(filterHtmlElements);
        }
        return true;
      }
      return false;
    }
    return true;
  }

  result.elements = result.elements.filter(filterHtmlElements);
  return js2xml(result, { compact: false, spaces: 2 });
};
export const htmlToFormattedString = (html: string): string => {
  // If input is not a string or is empty, return it as is
  if (!html || typeof html !== 'string') {
    return html as string;
  }

  // Remove HTML comments
  html = html.replace(/<!--[\s\S]*?-->/g, '');

  // First normalize line breaks and remove unnecessary whitespace
  html = html
    .replace(/<br\s*\/?>/gi, '\n')
    .replace(/\s+/g, ' ')
    .trim();

  // Process TextComplement sections first
  html = html.replace(/<TextComplement[^>]*>[\s\S]*?<\/TextComplement>/gi, match => {
    const caption = match.match(/<ComplCaption>([\s\S]*?)<\/ComplCaption>/i)?.[1] || '';
    const body = match.match(/<ComplBody>([\s\S]*?)<\/ComplBody>/i)?.[1] || '';

    // Clean the caption and body text
    const cleanCaption = caption.replace(/<[^>]+>/g, '').trim();
    const cleanBody = body.replace(/<[^>]+>/g, '').trim();

    // Only include caption if it exists, and add double line breaks
    return '\n\n' + (cleanCaption ? `${cleanCaption}: ${cleanBody}` : cleanBody);
  });

  // Process remaining Text tags and other content
  html = html
    .replace(/<Text[^>]*>/gi, '\n\n')
    .replace(/<\/Text>/gi, '\n\n')
    .replace(/<p[^>]*>/gi, '\n\n')
    .replace(/<\/p>/gi, '\n\n')
    .replace(/<div[^>]*>/gi, '\n')
    .replace(/<\/div>/gi, '\n');

  // Clean up spans and other inline elements
  html = html.replace(/<span[^>]*>([\s\S]*?)<\/span>/gi, '$1').replace(/<[^>]+>/g, ' '); // Remove any remaining tags

  // Clean up the text
  return html
    .split('\n')
    .map(line => line.trim())
    .filter(line => line) // Remove empty lines
    .join('\n')
    .replace(/\n{3,}/g, '\n\n') // Replace multiple newlines with double newlines
    .trim();
};

type Rules = 'conversationMemberMention' | 'pieceOfInformationMention' | 'taskMention';

const rulesMap: Record<Rules, Rule> = {
  conversationMemberMention: {
    filter: node => {
      return (
        node.nodeName === 'SPAN' &&
        node.classList.contains('mention') &&
        node.classList.contains('conversation-member-mention')
      );
    },
    replacement: (content, node) => {
      const typedNode = node as HTMLElement;
      const dataId = typedNode.getAttribute('data-id')!;
      const dataLabel = typedNode.getAttribute('data-label')!;
      return formatMention({ type: 'conversationMember', id: dataId, label: dataLabel });
    }
  },
  pieceOfInformationMention: {
    filter: node => {
      return (
        node.nodeName === 'SPAN' &&
        node.classList.contains('mention') &&
        node.classList.contains('piece-of-information-mention')
      );
    },
    replacement: (content, node) => {
      const typedNode = node as HTMLElement;
      const dataId = typedNode.getAttribute('data-id')!;
      const dataLabel = typedNode.getAttribute('data-label')!;
      return formatMention({ type: 'pieceOfInformation', id: dataId, label: dataLabel });
    }
  },
  taskMention: {
    filter: node => {
      return (
        node.nodeName === 'SPAN' &&
        node.classList.contains('mention') &&
        node.classList.contains('task-mention')
      );
    },
    replacement: (content, node) => {
      const typedNode = node as HTMLElement;
      const dataId = typedNode.getAttribute('data-id')!;
      const dataLabel = typedNode.getAttribute('data-label')!;
      return formatMention({ type: 'task', id: dataId, label: dataLabel });
    }
  }
};
type ExtensionName = keyof typeof rulesMap;

export const htmlToMarkdown = (html: string, enabledExtensions?: ExtensionName[]) => {
  const converter = new TurndownService();
  enabledExtensions?.forEach(extension => {
    converter.addRule(extension, rulesMap[extension]);
  });
  const markdown = converter.turndown(html);
  return markdown.replace(/<\/?[^>]+(>|$)/g, ''); // Strip any remaining HTML tags
};
