'use client';

import { debounce } from 'lodash';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

export const useUpdateQueryParams = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const addQueryParams = useCallback(
    (toAddParams: Record<string, string | undefined>) => {
      const params = new URLSearchParams(searchParams.toString());

      for (const [name, value] of Object.entries(toAddParams)) {
        if (value) {
          params.set(name, value);
        } else {
          params.delete(name);
        }
      }

      const queryParams = params.toString();

      const url = `${pathname}?${queryParams}`;
      router.replace(url);
    },
    [searchParams, router]
  );

  const debouncedAddQueryParams = (debounceTime: number) => debounce(addQueryParams, debounceTime);

  const removeQueryParams = useCallback(
    (toRemoveParams: string[]) => {
      const params = new URLSearchParams(searchParams.toString());
      for (const name of toRemoveParams) {
        params.delete(name);
      }
      const queryParams = params.toString();
      const url = `${pathname}?${queryParams}`;
      router.replace(url);
    },
    [searchParams, router]
  );

  const removeQueryParam = useCallback(
    (name: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.delete(name);
      const queryParams = params.toString();
      const url = `${pathname}?${queryParams}`;
      router.replace(url);
    },
    [searchParams, router]
  );

  return { addQueryParams, debouncedAddQueryParams, removeQueryParams, removeQueryParam };
};
