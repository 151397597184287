'use client';

import {
  Box,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Popover,
  Portal,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { Editor } from '@tiptap/react';
import {
  BoldIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  ItalicIcon,
  ListIcon,
  ListOrderedIcon,
  RedoIcon,
  TableIcon,
  UnderlineIcon,
  UndoIcon
} from 'lucide-react';
import React from 'react';

type RichTextEditorControl = {
  content: React.ElementType;
  command: (editor: Editor | null) => void;
  canBeActive: boolean;
  isActive: (editor: Editor | null) => boolean;
};

export const RICH_TEXT_EDITOR_BOLD_CONTROL: RichTextEditorControl = {
  content: BoldIcon,
  command: (editor: Editor | null) => editor?.chain().focus().toggleBold().run(),
  canBeActive: true,
  isActive: (editor: Editor | null) => editor?.isActive('bold') ?? false
};

export const RICH_TEXT_EDITOR_ITALIC_CONTROL: RichTextEditorControl = {
  content: ItalicIcon,
  command: (editor: Editor | null) => editor?.chain().focus().toggleItalic().run(),
  canBeActive: true,
  isActive: (editor: Editor | null) => editor?.isActive('italic') ?? false
};

export const RICH_TEXT_EDITOR_UNDERLINE_CONTROL: RichTextEditorControl = {
  content: UnderlineIcon,
  command: (editor: Editor | null) => editor?.chain().focus().toggleUnderline().run(),
  canBeActive: true,
  isActive: (editor: Editor | null) => editor?.isActive('underline') ?? false
};

export const RICH_TEXT_EDITOR_HEADING_1_CONTROL: RichTextEditorControl = {
  content: Heading1Icon,
  command: (editor: Editor | null) => editor?.chain().focus().toggleHeading({ level: 1 }).run(),
  canBeActive: false,
  isActive: (editor: Editor | null) => editor?.isActive('heading', { level: 1 }) ?? false
};

export const RICH_TEXT_EDITOR_HEADING_2_CONTROL: RichTextEditorControl = {
  content: Heading2Icon,
  command: (editor: Editor | null) => editor?.chain().focus().toggleHeading({ level: 2 }).run(),
  canBeActive: false,
  isActive: (editor: Editor | null) => editor?.isActive('heading', { level: 2 }) ?? false
};

export const RICH_TEXT_EDITOR_HEADING_3_CONTROL: RichTextEditorControl = {
  content: Heading3Icon,
  command: (editor: Editor | null) => editor?.chain().focus().toggleHeading({ level: 3 }).run(),
  canBeActive: false,
  isActive: (editor: Editor | null) => editor?.isActive('heading', { level: 3 }) ?? false
};

export const RICH_TEXT_EDITOR_TABLE_CONTROL: RichTextEditorControl = {
  content: TableIcon,
  command: () => {},
  canBeActive: false,
  isActive: () => false
};

export const RICH_TEXT_EDITOR_ORDERED_LIST_CONTROL: RichTextEditorControl = {
  content: ListOrderedIcon,
  command: (editor: Editor | null) => editor?.chain().focus().toggleOrderedList().run(),
  canBeActive: false,
  isActive: (editor: Editor | null) => editor?.isActive('orderedList') ?? false
};

export const RICH_TEXT_EDITOR_UNORDERED_LIST_CONTROL: RichTextEditorControl = {
  content: ListIcon,
  command: (editor: Editor | null) => editor?.chain().focus().toggleBulletList().run(),
  canBeActive: false,
  isActive: (editor: Editor | null) => editor?.isActive('bulletList') ?? false
};

export const RICH_TEXT_EDITOR_REDO_CONTROL: RichTextEditorControl = {
  content: RedoIcon,
  command: (editor: Editor | null) => editor?.chain().focus().redo().run(),
  canBeActive: false,
  isActive: () => false
};

export const RICH_TEXT_EDITOR_UNDO_CONTROL: RichTextEditorControl = {
  content: UndoIcon,
  command: (editor: Editor | null) => editor?.chain().focus().undo().run(),
  canBeActive: false,
  isActive: () => false
};

type RichTextEditorControlGroup = {
  controls: RichTextEditorControl[];
};

type RichTextEditorToolbarProps = {
  editor: Editor | null;
  toolbar: {
    controlsGroups: RichTextEditorControlGroup[];
  };
  styles: {
    px: number;
  };
};

export const RichTextEditorToolbar = ({ toolbar, editor, styles }: RichTextEditorToolbarProps) => {
  return (
    <Flex
      w="full"
      bgColor={'white'}
      position="sticky"
      top={0}
      zIndex={1}
      align={'center'}
      gap={4}
      h={16}
      minH={16}
      px={styles.px}
    >
      {toolbar.controlsGroups.map((group, index) => (
        <ToolbarGroup key={index} controls={group.controls} editor={editor} />
      ))}
    </Flex>
  );
};

const ToolbarGroup = ({
  controls,
  editor
}: {
  controls: RichTextEditorControl[];
  editor: Editor | null;
}) => {
  return (
    <Box>
      <ButtonGroup size="sm" variant="outline" attached>
        {controls.map((control, index) => {
          if (control === RICH_TEXT_EDITOR_TABLE_CONTROL) {
            return <TablePicker key={index} editor={editor} />;
          }
          return (
            <IconButton
              variant="outline"
              key={index}
              bgColor={control.isActive(editor) ? 'gray.100' : undefined}
              onClick={() => {
                control.command(editor);
              }}
            >
              <control.content />
            </IconButton>
          );
        })}
      </ButtonGroup>
    </Box>
  );
};

const TablePicker = ({ editor }: { editor: Editor | null }) => {
  const { open, setOpen } = useDisclosure();
  const [hoveredCells, setHoveredCells] = React.useState({ rows: 0, cols: 0 });
  const maxRows = 8;
  const maxCols = 8;

  const insertTable = (rows: number, cols: number) => {
    if (editor) {
      editor.chain().focus().insertTable({ rows, cols, withHeaderRow: true }).run();
    }
  };

  return (
    <Popover.Root
      open={open}
      onOpenChange={({ open }) => {
        setOpen(open);
      }}
    >
      <Popover.Trigger asChild>
        <IconButton variant="outline" aria-label="Insert table">
          <TableIcon />
        </IconButton>
      </Popover.Trigger>
      <Portal>
        <Popover.Positioner>
          <Popover.Content w="auto" p={0}>
            <Popover.Body p={2}>
              <Text mb={2} fontSize="sm" color="gray.600">
                {hoveredCells.rows > 0
                  ? `${hoveredCells.rows} x ${hoveredCells.cols} Table`
                  : 'Insert Table'}
              </Text>
              <Grid templateColumns={`repeat(${maxCols}, 1fr)`} gap={1}>
                {Array.from({ length: maxRows * maxCols }).map((_, index) => {
                  const row = Math.floor(index / maxCols) + 1;
                  const col = (index % maxCols) + 1;
                  return (
                    <GridItem
                      key={index}
                      w="20px"
                      h="20px"
                      bg={
                        row <= hoveredCells.rows && col <= hoveredCells.cols
                          ? 'blue.500'
                          : 'gray.200'
                      }
                      cursor="pointer"
                      onMouseEnter={() => setHoveredCells({ rows: row, cols: col })}
                      onClick={() => insertTable(hoveredCells.rows, hoveredCells.cols)}
                    />
                  );
                })}
              </Grid>
            </Popover.Body>
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  );
};
