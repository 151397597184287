import { z } from 'zod';
import { AgentType } from '../agent';
import { TagGroupType } from '../tag';
import { EnumMap } from '../utils';

export const projectTypeSchema = z.enum(['CONSTRUCTION_I_TWO']);

export type ProjectType = z.infer<typeof projectTypeSchema>;

export const PROJECT_TYPES: Record<ProjectType, { id: ProjectType; getLabel: () => string }> = {
  CONSTRUCTION_I_TWO: {
    id: 'CONSTRUCTION_I_TWO',
    getLabel: () => 'RIB iTWO'
  }
};

export const PROJECT_TYPE_TO_AGENT_TYPE: Record<ProjectType, AgentType> = {
  CONSTRUCTION_I_TWO: 'COST_ESTIMATOR'
};

export const PROJECT_TYPE_TO_TAG_GROUP_TYPE: Record<ProjectType, TagGroupType[]> = {
  CONSTRUCTION_I_TWO: [
    'PROJECT',
    'CONSTRUCTION_PROJECT_ENGINEERING_OFFICE',
    'CONSTRUCTION_PROJECT_OWNER'
  ]
};
