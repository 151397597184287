import { z } from 'zod';

export const tagGroupTypeSchema = z.enum([
  'PROJECT',
  'CONSTRUCTION_PROJECT_OWNER',
  'CONSTRUCTION_PROJECT_ENGINEERING_OFFICE'
]);
export type TagGroupType = z.infer<typeof tagGroupTypeSchema>;

export const tagTypeSchema = z.enum(['CONSTRUCTION_TEMPLATE_PROJECT']);
export type TagType = z.infer<typeof tagTypeSchema>;
