import {
  FieldType,
  removeNullish,
  TableRowDataType,
  TableRowDto,
  TableRowLinkedRowType,
  UpsertTableRow,
  UpsertTableRowDataType,
  UpsertTableRowLinkedRowType
} from '../../../types';
import { formatNumber } from '../../number';

export const rowValueToString = (value: TableRowDataType | undefined): string => {
  if (value === undefined) {
    return '';
  }
  if (isTableRowLinkedRowTypeArray(value)) {
    return value.linkedRows.map(linkedRow => linkedRow.primaryFieldValue).join(', ');
  }
  if (typeof value === 'number') {
    return formatNumber(value) ?? '';
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (value === null) {
    return '';
  }
  return value.toString();
};

export const rowValueToNumber = (value: TableRowDataType | undefined): number => {
  if (value === undefined) {
    return 0;
  }
  return Number(value);
};

export const fieldTypeToExportFormat: Record<
  FieldType,
  {
    toExportFormat: (value: TableRowDataType) => string | null;
    toTableRowValue: (value: string) => TableRowDataType;
  }
> = {
  CHECKBOX: {
    toExportFormat: value => (value === true ? 'true' : value === false ? 'false' : ''),
    toTableRowValue: value => {
      if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
      }
      return Boolean(value);
    }
  },
  DATE: {
    toExportFormat: value => {
      if (value instanceof Date) {
        return value.toISOString().split('T')[0]!;
      }
      return null;
    },
    toTableRowValue: value => {
      if (typeof value === 'string' && value) {
        return new Date(value);
      }
      return null;
    }
  },
  DATE_TIME: {
    toExportFormat: value => {
      if (value instanceof Date) {
        return value.toISOString();
      }
      return value as string | null;
    },
    toTableRowValue: value => {
      if (typeof value === 'string' && value) {
        return new Date(value);
      }
      return null;
    }
  },
  FILE_ATTACHMENT: {
    toExportFormat: () => null,
    toTableRowValue: () => null // Cannot convert clipboard text to file attachments
  },
  LINKED_ROW: {
    toExportFormat: value => {
      if (isTableRowLinkedRowTypeArray(value)) {
        return JSON.stringify({
          ...value,
          linkedRows: value.linkedRows.map(linkedRow => ({
            ...linkedRow,
            recommendationScore: null
          }))
        });
      }
      return null;
    },
    toTableRowValue: value => {
      if (typeof value === 'string') {
        try {
          const parsed = JSON.parse(value) as TableRowLinkedRowType;
          return {
            ...parsed,
            linkedRows: parsed.linkedRows.map(linkedRow => ({
              ...linkedRow,
              recommendationScore: null
            }))
          };
        } catch {
          return null;
        }
      }
      return null;
    }
  },
  MULTI_SELECT: {
    toExportFormat: value => {
      if (Array.isArray(value)) {
        return JSON.stringify(value);
      }
      return '';
    },
    toTableRowValue: value => {
      if (typeof value === 'string') {
        try {
          const parsed = JSON.parse(value);
          return Array.isArray(parsed) ? parsed : [value];
        } catch {
          return [value];
        }
      }
      return null;
    }
  },
  NUMBER: {
    toExportFormat: value => (value as number | null)?.toString() ?? null,
    toTableRowValue: value => {
      if (value === null || value === undefined || value === '') {
        return null;
      }
      const num = Number(value);
      return isNaN(num) ? null : num;
    }
  },
  SINGLE_SELECT: {
    toExportFormat: value => value as string | null,
    toTableRowValue: value => value as string | null
  },
  LONG_TEXT: {
    toExportFormat: value => value as string | null,
    toTableRowValue: value => value as string | null
  },
  OBJECT: {
    toExportFormat: value => {
      if (value === null || value === undefined) {
        return '';
      }
      return JSON.stringify(value);
    },
    toTableRowValue: value => {
      if (typeof value === 'string') {
        try {
          return JSON.parse(value);
        } catch {
          return null;
        }
      }
      return null;
    }
  },
  SHORT_TEXT: {
    toExportFormat: value => value as string | null,
    toTableRowValue: value => value as string | null
  }
};

export const isTableRowLinkedRowTypeArray = (
  value: TableRowDataType
): value is TableRowLinkedRowType => {
  return typeof value === 'object' && value !== null && 'linkedRows' in value;
};

export const isUpsertTableRowLinkedRowTypeArray = (
  value: UpsertTableRowDataType
): value is UpsertTableRowLinkedRowType => {
  return typeof value === 'object' && value !== null && 'linkedRows' in value;
};

type TableRowDtoWithOptionalFields = Omit<
  TableRowDto,
  'parentRowId' | 'parentTableRowId' | 'proposedChange' | 'primaryFieldValue'
> & {
  id: string;
  parentRowId?: string | null;
  parentTableRowId?: string | null;
  primaryFieldValue?: TableRowDataType;
  proposedChange?: any;
};

export const convertDtoToUpsertTableRow = (dto: TableRowDtoWithOptionalFields): UpsertTableRow => {
  const { id, parentRowId, parentTableRowId, primaryFieldValue, proposedChange, ...rest } = dto;
  return {
    id,
    parentRowId,
    parentTableRowId,
    ...rest
  };
};

export const isLeafRow = (row: TableRowDto, rows: TableRowDto[]) => {
  const parentRowIds = new Set(rows.filter(r => !!r.parentRowId).map(r => r.parentRowId));
  return !parentRowIds.has(row.id);
};

export const remapFieldsInRows = <TRow extends Record<string, unknown>>(
  rows: TRow[],
  fieldIdMappingSourceToDestination: Record<string, string>
): TRow[] => {
  return rows.map(row =>
    Object.fromEntries(
      Object.entries(row)
        .filter(([fieldId]) => fieldId in fieldIdMappingSourceToDestination)
        .map(([fieldId, value]) => [fieldIdMappingSourceToDestination[fieldId], value])
    )
  );
};

export const remapFieldInRow = <TRow extends Record<string, unknown>>(
  row: TRow,
  fieldIdToNewFieldIdMap: Record<string, string>
): TRow => {
  return remapFieldsInRows([row], fieldIdToNewFieldIdMap)[0]!;
};

export const getMaxChildHierarchyDepthForRows = (
  rows: { id: string; parentRowId?: string | null | undefined }[]
) => {
  const getDepth = (rowId: string, visited = new Set<string>()): number => {
    if (visited.has(rowId)) {
      return 0; // Prevent infinite recursion on circular references
    }
    visited.add(rowId);

    const childRows = rows.filter(r => r.parentRowId === rowId);
    if (childRows.length === 0) {
      return 0;
    }

    const childDepths = childRows.map(r => getDepth(r.id, visited));
    return 1 + Math.max(...childDepths);
  };

  const rootRows = rows.filter(r => !r.parentRowId);
  if (rootRows.length === 0) {
    return 0;
  }

  const depths = rootRows.map(r => getDepth(r.id));
  return Math.max(...depths);
};

export const getChildHierarchyDepthForRow = (
  row: { id: string; parentRowId?: string | null },
  rows: { id: string; parentRowId?: string | null }[]
): number => {
  const childRows = rows.filter(r => r.parentRowId === row.id);
  if (childRows.length === 0) {
    return 0;
  }
  const depths = childRows.map(r => getChildHierarchyDepthForRow(r, rows));
  return 1 + Math.max(...depths);
};

export const getRowsAndItsParentsRowIds = (
  rows: TableRowDto[],
  rowIds: string[]
): TableRowDto[] => {
  const matchingRows = rows.filter(row => rowIds.includes(row.id));
  const parentRowIds = matchingRows.map(row => row.parentRowId).filter(removeNullish);

  if (parentRowIds.length === 0) {
    return matchingRows;
  }

  return [...matchingRows, ...getRowsAndItsParentsRowIds(rows, parentRowIds)];
};

export const isChildRowOfRow = ({
  childRow,
  parentRow,
  allRows
}: {
  childRow: TableRowDto;
  parentRow: TableRowDto;
  allRows: TableRowDto[];
}): boolean => {
  const parentRowOfChildRow = allRows.find(r => r.id === childRow.parentRowId);
  if (!parentRowOfChildRow) {
    return false;
  }
  if (parentRowOfChildRow.id === parentRow.id) {
    return true;
  }
  return isChildRowOfRow({ childRow: parentRowOfChildRow, parentRow, allRows });
};

export const getChildRowsOfRow = ({
  row,
  rows
}: {
  row: TableRowDto;
  rows: TableRowDto[];
}): TableRowDto[] => {
  return rows.filter(r => r.parentRowId === row.id);
};
