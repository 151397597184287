'use client';

import { Controller, FieldValues } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { RichTextInputEditor } from '../rich-text';

export const RichTextInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  size = 'md',
  placeholder,
  autoFocus,
  onBlur,
  backgroundColor = 'white'
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder?: string;
  autoFocus?: boolean;
  onBlur?: () => void;
  backgroundColor?: string;
}) => {
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormInput
            form={form}
            name={name}
            root={root}
            label={label}
            size={size}
            errorProps={errorProps}
            hideError={hideError}
            helperProps={helperProps}
            helperText={helperText}
            labelProps={labelProps}
            isRequired={isRequired}
            isDisabled={isDisabled}
          >
            <RichTextInputEditor
              content={field.value}
              setContent={field.onChange}
              placeholder={placeholder}
              autoFocus={autoFocus}
              onBlur={onBlur}
              backgroundColor={backgroundColor}
            />
          </FormInput>
        );
      }}
    />
  );
};
