import TextStyle from '@tiptap/extension-text-style';

export const CustomTextStyle = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: null,
        parseHTML: element => element.style.fontSize,
        renderHTML: attributes => {
          return attributes.fontSize ? { style: `font-size: ${attributes.fontSize}` } : {};
        }
      }
    };
  }
});
