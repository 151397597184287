import { ToUploadFile } from '../../types';

export const getFileNameWithoutExtension = (fileName: string) => {
  return fileName.split('.').slice(0, -1).join('.');
};

export const getFileNameAndExtension = (fileName: string) => {
  const extension = fileName.split('.').pop()!;
  const name = getFileNameWithoutExtension(fileName);
  return { name, extension };
};

export const getFullFileName = (fileName: string, extension: string) => {
  return `${fileName}.${extension}`;
};

export const getFileContentType = (extension: string): string | undefined => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return 'application/pdf';
    case 'xml':
      return 'application/xml';
    case 'txt':
      return 'text/plain';
    case 'json':
      return 'application/json';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    case 'bmp':
      return 'image/bmp';
    case 'zip':
      return 'application/zip';
    case 'rar':
      return 'application/x-rar-compressed';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    default:
      return undefined; // Let Supabase infer the type
  }
};

const BINARY_EXTENSIONS = [
  'pdf',
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'zip',
  'rar',
  'docx',
  'xlsx',
  'pptx'
];

export const isBinaryFile = (extension: string): boolean => {
  return BINARY_EXTENSIONS.includes(extension.toLowerCase());
};

export const getFileBuffer = (file: ToUploadFile) => {
  const fileBuffer = isBinaryFile(file.extension)
    ? Buffer.from(file.content, 'base64') // Decode base64 for binary files
    : Buffer.from(file.content, 'utf-8'); // Use UTF-8 for text files

  return fileBuffer;
};
