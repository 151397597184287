'use client';

import '../styles.css';

import { Stack } from '@chakra-ui/react';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';

import { EditorContent, useEditor } from '@tiptap/react';
import { CustomTextStyle } from '../extensions';
import {
  RICH_TEXT_EDITOR_BOLD_CONTROL,
  RICH_TEXT_EDITOR_HEADING_1_CONTROL,
  RICH_TEXT_EDITOR_HEADING_2_CONTROL,
  RICH_TEXT_EDITOR_HEADING_3_CONTROL,
  RICH_TEXT_EDITOR_ITALIC_CONTROL,
  RICH_TEXT_EDITOR_ORDERED_LIST_CONTROL,
  RICH_TEXT_EDITOR_REDO_CONTROL,
  RICH_TEXT_EDITOR_UNDERLINE_CONTROL,
  RICH_TEXT_EDITOR_UNDO_CONTROL,
  RICH_TEXT_EDITOR_UNORDERED_LIST_CONTROL,
  RichTextEditorToolbar
} from '../toolbar';

interface RichTextInputEditorProps {
  content: string;
  setContent: (content: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  height?: string;
  onBlur?: () => void;
  backgroundColor?: string;
}

export const RichTextInputEditor = ({
  content,
  setContent,
  placeholder,
  autoFocus,
  height = '100%',
  onBlur,
  backgroundColor = 'white'
}: RichTextInputEditorProps) => {
  const editor = useEditor(
    {
      immediatelyRender: false,
      autofocus: autoFocus ? 'start' : false,
      editable: true,
      extensions: [
        Document,
        Paragraph,
        Text,
        CustomTextStyle.configure({ mergeNestedSpanStyles: true }),
        Bold,
        HardBreak,
        ListItem,
        Link.configure({
          openOnClick: true,
          autolink: true,
          linkOnPaste: true
        }),
        Heading,
        OrderedList.configure({
          keepAttributes: true,
          keepMarks: true
        }),
        BulletList,
        Blockquote,
        Bold,
        Italic,
        Strike,
        Underline,
        Placeholder.configure({ placeholder }),
        History,
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell
      ],
      content,
      onUpdate: ({ editor }) => {
        setContent(editor.getHTML());
      },
      onBlur: () => {
        onBlur?.();
      }
    },
    []
  );

  return (
    <Stack w={'full'} h={'full'} gap={0}>
      <EditorContent
        editor={editor}
        onClick={() => editor?.commands.focus()}
        style={{
          height,
          minHeight: '160px',
          textAlign: 'left',
          borderWidth: 1,
          borderRadius: 10,
          borderColor: 'gray.200',
          padding: 2,
          backgroundColor
        }}
      />
      <RichTextEditorToolbar
        editor={editor}
        styles={{ px: 0 }}
        toolbar={{
          controlsGroups: [
            {
              controls: [
                RICH_TEXT_EDITOR_BOLD_CONTROL,
                RICH_TEXT_EDITOR_ITALIC_CONTROL,
                RICH_TEXT_EDITOR_UNDERLINE_CONTROL
              ]
            },
            {
              controls: [
                RICH_TEXT_EDITOR_HEADING_1_CONTROL,
                RICH_TEXT_EDITOR_HEADING_2_CONTROL,
                RICH_TEXT_EDITOR_HEADING_3_CONTROL
              ]
            },
            {
              controls: [
                RICH_TEXT_EDITOR_ORDERED_LIST_CONTROL,
                RICH_TEXT_EDITOR_UNORDERED_LIST_CONTROL
              ]
            },
            {
              controls: [RICH_TEXT_EDITOR_REDO_CONTROL, RICH_TEXT_EDITOR_UNDO_CONTROL]
            }
          ]
        }}
      />
    </Stack>
  );
};
