import { ExportTable, TableRowDto, ToUploadFile } from '@company/common/types';
import { json2xml, xml2json } from 'xml-js';
import { getChildRowsOfRow, rowValueToString } from '../../table';
import { ReferenceNumberConfig } from '../i-two';
import { findXmlElementByName, XmlDocument, XmlElement, XmlNode } from '../xml-converter';
import { FieldReferenceIdToFieldId, GaebBoqHierarchy } from './types';
import { getDefaultGaebXml } from './default-gaeb-xml';
import {
  getCategoryReferenceNumberPart,
  getFieldReferenceIdToFieldId,
  getItemReferenceNumberPart,
  removeTrailingDot
} from './utils';

type ProjectInfo = {
  name: string;
  description?: string;
  currency: string;
};

export const exportTableAsGaebFile = ({
  table,
  boqHierarchies,
  ...rest
}: {
  table: ExportTable;
  boqHierarchies: GaebBoqHierarchy[];
} & (
  | {
      gaebXml: string;
      project?: ProjectInfo;
    }
  | {
      project: ProjectInfo;
    }
)): ToUploadFile => {
  const gaebXmlString = 'gaebXml' in rest ? rest.gaebXml : getDefaultGaebXml(rest.project);
  let gaebXmlJson: XmlDocument = JSON.parse(
    xml2json(gaebXmlString, {
      compact: false,
      spaces: 2
    })
  );

  updateGaebBoQXml({
    gaebXmlJson,
    table,
    project: rest.project,
    boqHierarchies
  });

  return {
    content: json2xml(JSON.stringify(gaebXmlJson), { compact: false, spaces: 2 }),
    name: `${table.name}.x83`,
    extension: 'x83'
  };
};

const updateGaebBoQXml = ({
  gaebXmlJson,
  table,
  project,
  boqHierarchies
}: {
  gaebXmlJson: XmlDocument;
  table: ExportTable;
  project?: ProjectInfo;
  boqHierarchies: GaebBoqHierarchy[];
}): XmlDocument => {
  const gaebElement = findXmlElementByName(gaebXmlJson.elements, 'GAEB');
  const awardElement = findXmlElementByName(gaebElement?.elements, 'Award');
  const boqElement = findXmlElementByName(awardElement?.elements, 'BoQ');
  const projectInfoElement = findXmlElementByName(gaebElement?.elements, 'PrjInfo');

  const fieldReferenceIdToFieldId = getFieldReferenceIdToFieldId({
    fields: table.fields.map(f => ({ id: f.id, referenceId: f.referenceId }))
  });

  const rows = table.rows;
  const boqRow = rows.find(r => r.parentRowId === null);

  if (boqElement) {
    const boqInfoElement = boqElement.elements?.find(
      e => e.type === 'element' && e.name === 'BoQInfo'
    );
    if (!boqInfoElement) {
      throw new Error('BoQInfo element not found');
    }
    boqElement.elements = [
      ...(boqElement.elements?.filter(
        e => e.type !== 'element' || (e.name !== 'BoQBody' && e.name !== 'BoQInfo')
      ) ?? []),
      updateBoqInfoElement({
        boqInfoElement,
        project: project
      }),
      createBoqBodyElement({
        childRows: rows.filter(r => r.parentRowId === boqRow?.id),
        allRows: rows,
        fieldReferenceIdToFieldId,
        boqHierarchies,
        boqHierarchyIndex: 0
      })
    ];
  }

  updateProjectInfoElement({
    projectInfoElement,
    project
  });

  return gaebXmlJson;
};

const createBoqBodyElement = ({
  childRows,
  allRows,
  fieldReferenceIdToFieldId,
  boqHierarchies,
  boqHierarchyIndex
}: {
  childRows: TableRowDto[];
  allRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
  boqHierarchies: GaebBoqHierarchy[];
  boqHierarchyIndex: number;
}): XmlNode => {
  const categoryRows = childRows.filter(row => isCategoryRow(row, fieldReferenceIdToFieldId));
  const itemRows = childRows.filter(row => !isCategoryRow(row, fieldReferenceIdToFieldId));

  const elements: XmlNode[] =
    categoryRows.length > 0
      ? categoryRows.map(row => {
          const nextChildRows = getChildRowsOfRow({ row, rows: allRows });
          return createBoqCtgyElement({
            categoryRow: row,
            childRows: nextChildRows,
            allRows,
            fieldReferenceIdToFieldId,
            boqHierarchies,
            boqHierarchyIndex
          });
        })
      : [
          createItemListElement({
            childRows: itemRows,
            fieldReferenceIdToFieldId,
            boqHierarchies
          })
        ];

  return {
    type: 'element',
    name: 'BoQBody',
    elements
  };
};

const isCategoryRow = (
  row: TableRowDto,
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId
): boolean => row[fieldReferenceIdToFieldId.unit] === '' || !row[fieldReferenceIdToFieldId.unit];

const createBoqCtgyElement = ({
  categoryRow,
  childRows,
  allRows,
  fieldReferenceIdToFieldId,
  boqHierarchies,
  boqHierarchyIndex
}: {
  categoryRow: TableRowDto;
  childRows: TableRowDto[];
  allRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
  boqHierarchies: GaebBoqHierarchy[];
  boqHierarchyIndex: number;
}): XmlNode => {
  const categoryName = removeTrailingDot(
    rowValueToString(categoryRow[fieldReferenceIdToFieldId.shortText])
  );
  const referenceNumberPart = getCategoryReferenceNumberPart({
    boqHierarchies,
    boqHierarchyIndex,
    referenceNumber: rowValueToString(categoryRow[fieldReferenceIdToFieldId.referenceNumber])
  });

  return {
    type: 'element',
    name: 'BoQCtgy',
    attributes: {
      ID: categoryRow.id,
      RNoPart: referenceNumberPart!
    },
    elements: [
      {
        type: 'element',
        name: 'LblTx',
        elements: [
          {
            type: 'element',
            name: 'p',
            elements: [
              {
                type: 'element',
                name: 'span',
                elements: [{ type: 'text', text: categoryName }]
              }
            ]
          }
        ]
      },
      createBoqBodyElement({
        childRows,
        allRows,
        fieldReferenceIdToFieldId,
        boqHierarchies,
        boqHierarchyIndex: boqHierarchyIndex + 1
      })
    ]
  };
};

const createItemListElement = ({
  childRows,
  fieldReferenceIdToFieldId,
  boqHierarchies
}: {
  childRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
  boqHierarchies: GaebBoqHierarchy[];
}): XmlNode => {
  return {
    type: 'element',
    name: 'Itemlist',
    elements: childRows.map(row => {
      return createItemElement({
        itemRow: row,
        fieldReferenceIdToFieldId,
        boqHierarchies
      });
    })
  };
};

const createItemElement = ({
  itemRow,
  fieldReferenceIdToFieldId,
  boqHierarchies
}: {
  itemRow: TableRowDto;
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
  boqHierarchies: GaebBoqHierarchy[];
}): XmlNode => {
  const unit = rowValueToString(itemRow[fieldReferenceIdToFieldId.unit]);
  const name = rowValueToString(itemRow[fieldReferenceIdToFieldId.shortText]);
  const description = rowValueToString(itemRow[fieldReferenceIdToFieldId.longText]);
  const descriptionLines = description.split('\n').filter(line => line.trim() !== '');
  const referenceNumber = rowValueToString(itemRow[fieldReferenceIdToFieldId.referenceNumber]);
  const { part, index } = getItemReferenceNumberPart({
    boqHierarchies,
    referenceNumber
  });

  return {
    type: 'element',
    name: 'Item',
    attributes: {
      ID: itemRow.id,
      RNoPart: part,
      ...(index ? { RNoIndex: index! } : {})
    },
    elements: [
      {
        type: 'element',
        name: 'LumpSumItem',
        elements: [
          {
            type: 'text',
            text: 'Yes'
          }
        ]
      },
      {
        type: 'element',
        name: 'UPBkdn',
        elements: [
          {
            type: 'text',
            text: 'Yes'
          }
        ]
      },
      {
        type: 'element',
        name: 'Qty',
        elements: [
          {
            type: 'text',
            text: '1.000'
          }
        ]
      },
      {
        type: 'element',
        name: 'QU',
        elements: [
          {
            type: 'text',
            text: unit
          }
        ]
      },
      {
        type: 'element',
        name: 'Description',
        elements: [
          {
            type: 'element',
            name: 'CompleteText',
            elements: [
              {
                type: 'element',
                name: 'DetailTxt',
                elements: [
                  {
                    type: 'element',
                    name: 'Text',
                    elements: [
                      {
                        type: 'element',
                        name: 'p',
                        attributes: {
                          style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
                        },
                        elements: descriptionLines.flatMap((line, index) => {
                          const descriptionElement = {
                            type: 'element' as const,
                            name: 'span',
                            attributes: {
                              style: 'font-family:Arial;font-size:10pt;Color:rgb(0,0,0);'
                            },
                            elements: [{ type: 'text' as const, text: line }]
                          };

                          if (index < descriptionLines.length - 1) {
                            return [descriptionElement, { type: 'element' as const, name: 'br' }];
                          }

                          return [descriptionElement];
                        })
                      }
                    ]
                  }
                ]
              },
              {
                type: 'element',
                name: 'OutlineText',
                elements: [
                  {
                    type: 'element',
                    name: 'OutlTxt',
                    elements: [
                      {
                        type: 'element',
                        name: 'TextOutlTxt',
                        elements: [
                          {
                            type: 'element',
                            name: 'p',
                            attributes: {
                              style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
                            },
                            elements: [
                              {
                                type: 'element',
                                name: 'span',
                                elements: [{ type: 'text', text: name }]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };
};

const updateBoqInfoElement = ({
  boqInfoElement,
  project
}: {
  boqInfoElement: XmlNode;
  project?: ProjectInfo;
}): XmlNode => {
  if (!project) {
    return boqInfoElement;
  }

  if (boqInfoElement.type !== 'element' || boqInfoElement.name !== 'BoQInfo') {
    throw new Error('BoQInfo element not found');
  }

  return {
    type: 'element',
    name: 'BoQInfo',
    elements: [
      {
        type: 'element',
        name: 'Name',
        elements: [{ type: 'text', text: project.name }]
      },
      {
        type: 'element',
        name: 'LblBoQ',
        elements: [{ type: 'text', text: project.description || '' }]
      },
      ...(boqInfoElement.elements?.filter(
        e => e.type !== 'element' || (e.name !== 'Name' && e.name !== 'LblBoQ')
      ) ?? [])
    ]
  };
};

const updateProjectInfoElement = ({
  projectInfoElement,
  project
}: {
  projectInfoElement: XmlElement | undefined;
  project?: ProjectInfo;
}) => {
  if (!projectInfoElement || !project) {
    return projectInfoElement;
  }

  projectInfoElement.elements = [
    {
      type: 'element',
      name: 'NamePrj',
      elements: [{ type: 'text', text: project.name }]
    },
    {
      type: 'element',
      name: 'LblPrj',
      elements: [{ type: 'text', text: project.description || '' }]
    },
    {
      type: 'element',
      name: 'Cur',
      elements: [{ type: 'text', text: project.currency }]
    }
  ];

  return projectInfoElement;
};
