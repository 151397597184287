import { z } from 'zod';

export * from './information-request';
export * from './memory';

export const agentTypeSchema = z.enum(['COST_ESTIMATOR', 'CONSTRUCTION_TENDER_ADMINISTRATOR']);
export enum AgentTypeEnum {
  COST_ESTIMATOR = 'COST_ESTIMATOR',
  CONSTRUCTION_TENDER_ADMINISTRATOR = 'CONSTRUCTION_TENDER_ADMINISTRATOR'
}
export type AgentType = z.infer<typeof agentTypeSchema>;
