import { z } from 'zod';
import { CreateTable } from '../table';

export const pieceOfInformationTemplateIdSchema = z.enum([
  'CONSTRUCTION_SUB_CONTRACTOR_DEFINITION_TABLES',
  'CONSTRUCTION_SUB_CONTRACTOR_AWARD_EMAIL_DRAFT'
]);
export type PieceOfInformationTemplateId = z.infer<typeof pieceOfInformationTemplateIdSchema>;

export type PieceOfInformationTemplate = {
  id: PieceOfInformationTemplateId;
  label: string;
} & (
  | {
      type: 'DOCUMENT';
      title: string;
      content: string;
      tagName: string;
    }
  | {
      type: 'EMAIL_DRAFT';
      tagName: string;
      subject: string;
      body: string;
    }
  | {
      type: 'TABLES';
      tables: (CreateTable & {
        label: string;
        tagName: string;
      })[];
    }
);
