'use client';

import { Box, Dialog, DialogRootProps, Heading } from '@chakra-ui/react';
import React from 'react';
import { CloseButton } from '../ui';

export interface SimpleDialogProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  header: {
    title: string | React.ReactElement;
    description?: string | React.ReactElement;
  };
  children: React.ReactElement;
  footer?: React.ReactNode;
  triggerComponent?: React.ReactNode;
  size?: DialogRootProps['size'];
  scrollBehavior?: DialogRootProps['scrollBehavior'];
  centered?: boolean;
  minHeight?: string;
}

export const SimpleDialog = ({
  isOpen,
  onOpen,
  onClose,
  header,
  children,
  footer,
  triggerComponent,
  size,
  scrollBehavior = 'inside',
  centered = true,
  minHeight
}: SimpleDialogProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <>
      {triggerComponent &&
        React.cloneElement(
          triggerComponent as React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>,
          {
            onClick: (e: React.MouseEvent) => {
              handleClick(e);
              (
                triggerComponent as React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>
              ).props.onClick?.(e);
            }
          }
        )}

      <Dialog.Root
        open={isOpen}
        onOpenChange={({ open }) => {
          if (!open) {
            onClose();
          }
        }}
        size={size}
        scrollBehavior={scrollBehavior}
        placement={centered ? 'center' : undefined}
      >
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <Dialog.Content minHeight={minHeight}>
            <Dialog.Header display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Heading fontSize={'xl'} mb={1} fontWeight={'medium'} color={'fg'}>
                  {header.title}
                </Heading>
                {header.description && (
                  <Box fontSize={'xs'} color={'GrayText'} fontWeight={'normal'} lineHeight={1.3}>
                    {header.description}
                  </Box>
                )}
              </Box>
              <Dialog.CloseTrigger asChild>
                <CloseButton />
              </Dialog.CloseTrigger>
            </Dialog.Header>

            <Dialog.Body>{children}</Dialog.Body>
            {footer ? <Dialog.Footer>{footer}</Dialog.Footer> : <Box h={4}></Box>}
          </Dialog.Content>
        </Dialog.Positioner>
      </Dialog.Root>
    </>
  );
};
