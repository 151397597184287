import React from 'react';

interface ProcessingListProps<T> {
  meetsConditions: (item: T) => boolean;
  onProcess: (item: T) => void;
  options?: {
    interval?: number;
  };
}

export const useProcessingList = <T>({
  meetsConditions,
  onProcess,
  options
}: ProcessingListProps<T>) => {
  const processingList = React.useRef<T[]>([]);

  const interval = options?.interval ?? 250;

  const processItems = React.useCallback(() => {
    if (processingList.current.length === 0) {
      return;
    }

    const remainingItems: T[] = [];
    processingList.current.forEach(item => {
      if (meetsConditions(item)) {
        onProcess(item);
      } else {
        remainingItems.push(item);
      }
    });

    processingList.current = remainingItems;
  }, [processingList, meetsConditions, onProcess]);

  const addToProcessingList = React.useCallback((item: T) => {
    processingList.current.push(item);
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      processItems();
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval, processItems]);

  return { addToProcessingList };
};
