import { Result } from 'neverthrow';
import { GAEBConverter } from './converter';
import { GAEB83, ResponseError } from './types';

export const getTypedGaebFile = ({
  gaeb83Xml
}: {
  gaeb83Xml: string;
}): Result<GAEB83, ResponseError> => {
  const gaebConverter = new GAEBConverter();
  return gaebConverter.fromXmlString(gaeb83Xml);
};
