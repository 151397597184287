import { Box, Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { SelectOption } from '../../../types/select';

export const SUGGESTION_MENU_ID = 'suggestion-menu';

export interface SuggestionListRef {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
}

interface SuggestionListProps {
  items: SelectOption[];
  clientRect: () => DOMRect | null;
  command: (props: { id: string; label: string }) => void;
  emptyState: React.ReactNode;
}

export const SuggestionList = React.forwardRef<SuggestionListRef, SuggestionListProps>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const selectItem = (index: number) => {
      const item = props.items[index];

      if (item) {
        props.command({ id: item.value, label: item.label });
      }
    };

    const upHandler = () => {
      setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    React.useEffect(() => setSelectedIndex(0), [props.items]);

    React.useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      }
    }));

    return (
      <Box
        bgColor="white"
        borderWidth={1}
        shadow={'xs'}
        borderRadius="0.7rem"
        display="flex"
        flexDirection="column"
        gap="0.1rem"
        overflow="auto"
        padding="0.4rem"
        position="relative"
        id={SUGGESTION_MENU_ID}
        minWidth="240px"
      >
        {props.items.length ? (
          props.items.map((item, index) => (
            <Button
              backgroundColor={index === selectedIndex ? 'gray.200' : 'white'}
              justifyContent="flex-start"
              color="black"
              textAlign="left"
              width="100%"
              key={index}
              onClick={() => selectItem(index)}
              _hover={{ backgroundColor: 'gray.100' }}
              _selected={{ backgroundColor: 'gray.200' }}
            >
              <Flex gap={2.5} alignItems="center">
                {item.startElement}
                <Box>
                  {item.label}
                  {item.description && (
                    <Box fontSize={'xs'} color="GrayText" fontWeight={'normal'} mt={-0.5}>
                      {item.description}
                    </Box>
                  )}
                </Box>
              </Flex>
            </Button>
          ))
        ) : (
          <Box fontSize={'sm'} color="GrayText">
            {props.emptyState}
          </Box>
        )}
      </Box>
    );
  }
);
