import { z } from 'zod';

export const taskStepReferenceIdSchema = z.enum([
  'COST_ESTIMATOR_CREATE_PROJECT_FROM_I_TWO_PROJECT',
  'COST_ESTIMATOR_CREATE_TEMPLATE_PROJECT_FROM_ITWO_PROJECT',
  'COST_ESTIMATOR_REIMPORT_ITWO_PROJECT',
  'COST_ESTIMATOR_FIND_SIMILAR_PROJECTS_FOR_COST_ESTIMATION',
  'COST_ESTIMATOR_ADD_COST_CALCULATION_TO_PROJECT_WORK_ITEMS',
  'COST_ESTIMATOR_EXPORT_ITWO_PROJECT_WITH_COST_CALCULATION',
  'COST_ESTIMATOR_COMBINE_MULTIPLE_PROJECTS_INTO_TEMPLATE_PROJECT',
  'COST_ESTIMATOR_CREATE_COMBINED_PROJECT_FROM_TEMPLATE_PROJECT',
  'TENDER_ADMINISTRATOR_CREATE_PROJECT',
  'TENDER_ADMINISTRATOR_SUB_CONTRACTOR_REVIEW_WORK_DEFINITION',
  'TENDER_ADMINISTRATOR_BREAK_DOWN_BOQ_INTO_AWARD_PACKAGES',
  'TENDER_ADMINISTRATOR_CREATE_EMAIL_SUB_CONTRACTOR_AWARD_PACKAGES',
  'TENDER_ADMINISTRATOR_IDENTIFY_OPPORTUNITIES',
  'TENDER_ADMINISTRATOR_SEND_EMAILS_TO_SUB_CONTRACTORS',
  'TENDER_ADMINISTRATOR_CHOOSE_AWARD_PACKAGE_DISTRIBUTION_METHOD',
  'TENDER_ADMINISTRATOR_CREATE_ZIP_SUB_CONTRACTOR_AWARD_PACKAGES'
]);

export type TaskStepReferenceId = z.infer<typeof taskStepReferenceIdSchema>;
