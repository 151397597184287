import { err, ok, Result } from '@company/common/neverthrow';
import { json2xml, xml2json } from 'xml-js';
import { htmlToFormattedString } from '../../strings';
import { standardizeUnit } from '../../unit';
import {
  findXmlElementByName,
  textXmlConverter,
  XmlConverter,
  XmlDocument,
  XmlElement,
  XmlNode
} from '../xml-converter';
import { GAEB83, GaebBoqCategory, GaebBoqItem, ResponseError } from './types';
import { cleanGaebString, createReferenceNumber, removeLeadingZeroes } from './utils';
/**
 * Converter for GAEB XML files.
 * Handles conversion between GAEB XML and TypeScript objects while preserving node order.
 */
export class GAEBConverter {
  fromXmlString(xmlString: string): Result<GAEB83, ResponseError> {
    const xmlDoc = JSON.parse(xml2json(xmlString, { compact: false, spaces: 2 }));
    return this.fromXmlDocument(xmlDoc);
  }

  fromXmlDocument(xmlDoc: XmlDocument): Result<GAEB83, ResponseError> {
    try {
      const rootElement = xmlDoc.elements.find(
        (element): element is XmlElement => element.type === 'element' && element.name === 'GAEB'
      );

      if (!rootElement) {
        return err({
          type: 'UNKNOWN',
          message: 'Invalid GAEB XML: Root element "GAEB" not found'
        });
      }

      const gaebRes = this.toJson(rootElement);
      if (gaebRes.isErr()) {
        return err(gaebRes.error);
      }

      addReferenceNumbersToGaeb(gaebRes.value);

      return ok(gaebRes.value);
    } catch (error) {
      return err({
        type: 'UNKNOWN',
        message: 'Unknown error during XML document to GAEB conversion'
      });
    }
  }

  /**
   * Convert GAEB object to XmlDocument
   */
  toXmlDocument(gaeb: GAEB83): Result<XmlDocument, ResponseError> {
    try {
      const rootNode = this.toXml(gaeb);
      if (rootNode.isErr()) {
        return err({
          type: 'UNKNOWN',
          message: 'Unknown error during GAEB to XML document conversion'
        });
      }

      const xmlDoc: XmlDocument = {
        declaration: {
          attributes: {
            version: '1.0'
          }
        },
        elements: [rootNode.value]
      };

      return ok(xmlDoc);
    } catch (error) {
      return err({
        type: 'UNKNOWN',
        message: 'Unknown error during GAEB to XML document conversion'
      });
    }
  }

  toJson(xml: XmlElement): Result<GAEB83, ResponseError> {
    const infoConverter = new XmlGAEBInfoConverter();
    const info = infoConverter.toJson(xml);
    const projectConverter = new XmlGAEBProjectInfoConverter();
    const project = projectConverter.toJson(xml);
    const awardConverter = new XmlGAEBAwardConverter();
    const award = awardConverter.toJson(xml);
    return ok({
      award,
      info,
      project
    } as any as GAEB83);
  }

  toXml(gaeb: GAEB83): Result<XmlElement, ResponseError> {
    const infoConverter = new XmlGAEBInfoConverter();
    const info = infoConverter.toXml(gaeb.info);
    const projectConverter = new XmlGAEBProjectInfoConverter();
    const project = projectConverter.toXml(gaeb.project);
    const awardConverter = new XmlGAEBAwardConverter();
    const award = awardConverter.toXml(gaeb.award);
    return ok({
      type: 'element',
      name: 'GAEB',
      children: [info, project, award]
    });
  }
}

export class XmlGAEBInfoConverter extends XmlConverter<GAEB83['info']> {
  toJson(xml: XmlElement): GAEB83['info'] {
    const info = findXmlElementByName(xml.elements, 'GAEBInfo');

    if (!info) {
      return {
        version: undefined,
        versionDate: undefined,
        date: undefined
      };
    }
    const version = findXmlElementByName(info.elements, 'Version');
    const versionDate = findXmlElementByName(info.elements, 'VersDate');
    const date = findXmlElementByName(info.elements, 'Date');
    const time = findXmlElementByName(info.elements, 'Time');

    const dateString = textXmlConverter.toJson(date);
    const timeString = textXmlConverter.toJson(time);
    const versionDateString = textXmlConverter.toJson(versionDate);
    const versionString = textXmlConverter.toJson(version);

    return {
      version: versionString,
      versionDate: versionDateString ? new Date(versionDateString) : undefined,
      date: dateString && timeString ? new Date(`${dateString}T${timeString}`) : undefined
    };
  }

  toXml(json: GAEB83['info']): XmlElement {
    return {
      type: 'element',
      name: 'GAEBInfo',
      elements: [
        json.version
          ? {
              type: 'element',
              name: 'Version',
              elements: [textXmlConverter.toXml(json.version)]
            }
          : undefined,
        json.versionDate
          ? {
              type: 'element',
              name: 'VersDate',
              elements: [textXmlConverter.toXml(json.versionDate.toISOString().split('T')[0])]
            }
          : undefined,
        json.date
          ? {
              type: 'element',
              name: 'Date',
              elements: [textXmlConverter.toXml(json.date.toISOString().split('T')[0])]
            }
          : undefined,
        json.date
          ? {
              type: 'element',
              name: 'Time',
              elements: [
                textXmlConverter.toXml(json.date.toISOString().split('T')[1]?.substring(0, 8))
              ]
            }
          : undefined
      ].filter(element => element !== undefined) as XmlElement[]
    };
  }
}

class XmlGAEBProjectInfoConverter extends XmlConverter<GAEB83['project']> {
  toJson(xml: XmlElement): GAEB83['project'] {
    const prjInfo = findXmlElementByName(xml.elements, 'PrjInfo');

    if (!prjInfo) {
      return {
        name: '',
        label: '',
        currency: '',
        currencyLabel: ''
      };
    }

    const namePrj = findXmlElementByName(prjInfo.elements, 'NamePrj');
    const lblPrj = findXmlElementByName(prjInfo.elements, 'LblPrj');
    const cur = findXmlElementByName(prjInfo.elements, 'Cur');
    const curLbl = findXmlElementByName(prjInfo.elements, 'CurLbl');

    return {
      name: textXmlConverter.toJson(namePrj) || '',
      label: textXmlConverter.toJson(lblPrj) || '',
      currency: textXmlConverter.toJson(cur) || '',
      currencyLabel: textXmlConverter.toJson(curLbl) || ''
    };
  }

  toXml(json: GAEB83['project']): XmlElement {
    return {
      type: 'element',
      name: 'PrjInfo',
      elements: [
        {
          type: 'element',
          name: 'NamePrj',
          elements: [textXmlConverter.toXml(json.name)]
        },
        {
          type: 'element',
          name: 'LblPrj',
          elements: [textXmlConverter.toXml(json.label)]
        },
        {
          type: 'element',
          name: 'Cur',
          elements: [textXmlConverter.toXml(json.currency)]
        },
        {
          type: 'element',
          name: 'CurLbl',
          elements: [textXmlConverter.toXml(json.currencyLabel)]
        }
      ]
    };
  }
}

class XmlGAEBAwardConverter extends XmlConverter<GAEB83['award']> {
  toJson(xml: XmlElement): GAEB83['award'] {
    const award = findXmlElementByName(xml.elements, 'Award');
    if (!award) {
      return {
        info: {
          category: '',
          currency: '',
          currencyLabel: '',
          openDate: new Date(),
          evalEndDate: new Date(),
          submissionLocation: '',
          constructionStartDate: undefined,
          constructionEndDate: undefined
        },
        owner: {
          name: '',
          address: {
            city: '',
            country: '',
            state: '',
            street: '',
            streetNumber: '',
            zipCode: ''
          }
        },
        addText: {
          sections: []
        },
        boq: {
          info: {
            date: new Date(),
            label: '',
            name: '',
            hierarchies: []
          },
          body: {
            categories: []
          }
        }
      };
    }

    const awardInfoConverter = new XmlGAEBAwardInfoConverter();
    const awardInfo = awardInfoConverter.toJson(award);

    const ownerConverter = new XmlGAEBAwardOwnerConverter();
    const owner = ownerConverter.toJson(award);

    const addTextConverter = new XmlGAEBAddTextConverter();
    const addText = addTextConverter.toJson(award);

    const boqConverter = new XmlGAEBBoqConverter();
    const boq = boqConverter.toJson(award);

    return {
      info: awardInfo,
      owner,
      addText,
      boq
    };
  }

  toXml(json: GAEB83['award']): XmlElement {
    const awardInfoConverter = new XmlGAEBAwardInfoConverter();
    const awardInfo = awardInfoConverter.toXml(json.info);

    const ownerConverter = new XmlGAEBAwardOwnerConverter();
    const owner = ownerConverter.toXml(json.owner);

    const addTextConverter = new XmlGAEBAddTextConverter();
    const addText = addTextConverter.toXml(json.addText);

    const boqConverter = new XmlGAEBBoqConverter();
    const boq = boqConverter.toXml(json.boq);

    return {
      type: 'element',
      name: 'Award',
      elements: [awardInfo, owner, addText, boq]
    };
  }
}

export class XmlGAEBAwardInfoConverter extends XmlConverter<GAEB83['award']['info']> {
  toJson(xml: XmlElement): GAEB83['award']['info'] {
    const awardInfo = findXmlElementByName(xml.elements, 'AwardInfo');
    if (!awardInfo) {
      return {
        category: '',
        currency: '',
        currencyLabel: '',
        openDate: new Date(),
        evalEndDate: new Date(),
        submissionLocation: '',
        constructionStartDate: undefined,
        constructionEndDate: undefined
      };
    }

    const cat = findXmlElementByName(awardInfo.elements, 'Cat');
    const cur = findXmlElementByName(awardInfo.elements, 'Cur');
    const curLbl = findXmlElementByName(awardInfo.elements, 'CurLbl');
    const openDate = findXmlElementByName(awardInfo.elements, 'OpenDate');
    const openTime = findXmlElementByName(awardInfo.elements, 'OpenTime');
    const evalEnd = findXmlElementByName(awardInfo.elements, 'EvalEnd');
    const submLoc = findXmlElementByName(awardInfo.elements, 'SubmLoc');
    const cnstStart = findXmlElementByName(awardInfo.elements, 'CnstStart');
    const cnstEnd = findXmlElementByName(awardInfo.elements, 'CnstEnd');

    const openDateString = textXmlConverter.toJson(openDate);
    const openTimeString = textXmlConverter.toJson(openTime);
    const evalEndString = textXmlConverter.toJson(evalEnd);
    const cnstStartString = textXmlConverter.toJson(cnstStart);
    const cnstEndString = textXmlConverter.toJson(cnstEnd);

    return {
      category: textXmlConverter.toJson(cat) || '',
      currency: textXmlConverter.toJson(cur) || '',
      currencyLabel: textXmlConverter.toJson(curLbl) || '',
      openDate:
        openDateString && openTimeString
          ? new Date(`${openDateString}T${openTimeString}`)
          : new Date(),
      evalEndDate: evalEndString ? new Date(evalEndString) : new Date(),
      submissionLocation: textXmlConverter.toJson(submLoc) || '',
      constructionStartDate: cnstStartString ? new Date(cnstStartString) : undefined,
      constructionEndDate: cnstEndString ? new Date(cnstEndString) : undefined
    };
  }

  toXml(json: GAEB83['award']['info']): XmlElement {
    return {
      type: 'element',
      name: 'AwardInfo',
      elements: [
        {
          type: 'element',
          name: 'Cat',
          elements: [textXmlConverter.toXml(json.category)]
        },
        {
          type: 'element',
          name: 'Cur',
          elements: [textXmlConverter.toXml(json.currency)]
        },
        {
          type: 'element',
          name: 'CurLbl',
          elements: [textXmlConverter.toXml(json.currencyLabel)]
        },
        {
          type: 'element',
          name: 'OpenDate',
          elements: json.openDate
            ? [textXmlConverter.toXml(json.openDate.toISOString().split('T')[0])]
            : []
        },
        {
          type: 'element',
          name: 'OpenTime',
          elements: json.openDate
            ? [textXmlConverter.toXml(json.openDate.toISOString().split('T')[1]?.substring(0, 8))]
            : []
        },
        {
          type: 'element',
          name: 'EvalEnd',
          elements: json.evalEndDate
            ? [textXmlConverter.toXml(json.evalEndDate.toISOString().split('T')[0])]
            : []
        },
        {
          type: 'element',
          name: 'SubmLoc',
          elements: json.submissionLocation ? [textXmlConverter.toXml(json.submissionLocation)] : []
        }
      ]
    };
  }
}

export class XmlGAEBAwardOwnerConverter extends XmlConverter<GAEB83['award']['owner']> {
  toJson(xml: XmlElement): GAEB83['award']['owner'] {
    const own = findXmlElementByName(xml.elements, 'OWN');
    if (!own) {
      return {
        name: '',
        address: {
          street: '',
          streetNumber: '',
          zipCode: '',
          city: '',
          country: '',
          state: ''
        }
      };
    }

    const address = findXmlElementByName(own.elements, 'Address');
    if (!address) {
      return {
        name: '',
        address: {
          street: '',
          streetNumber: '',
          zipCode: '',
          city: '',
          country: '',
          state: ''
        }
      };
    }

    const name = findXmlElementByName(address.elements, 'Name1');
    const street = findXmlElementByName(address.elements, 'Street');
    const streetNumber = findXmlElementByName(address.elements, 'StreetNo');
    const zipCode = findXmlElementByName(address.elements, 'PCode');
    const city = findXmlElementByName(address.elements, 'City');
    const country = findXmlElementByName(address.elements, 'Country');
    const state = findXmlElementByName(address.elements, 'State');

    return {
      name: textXmlConverter.toJson(name) || '',
      address: {
        street: textXmlConverter.toJson(street) || '',
        streetNumber: textXmlConverter.toJson(streetNumber) || '',
        zipCode: textXmlConverter.toJson(zipCode) || '',
        city: textXmlConverter.toJson(city) || '',
        country: textXmlConverter.toJson(country) || '',
        state: textXmlConverter.toJson(state) || ''
      }
    };
  }

  toXml(json: GAEB83['award']['owner']): XmlElement {
    return {
      type: 'element',
      name: 'OWN',
      elements: [
        {
          type: 'element',
          name: 'Address',
          elements: [
            {
              type: 'element',
              name: 'Name1',
              elements: [textXmlConverter.toXml(json.name)]
            },
            {
              type: 'element',
              name: 'Street',
              elements: json.address?.street ? [textXmlConverter.toXml(json.address?.street)] : []
            },
            {
              type: 'element',
              name: 'StreetNo',
              elements: json.address?.streetNumber
                ? [textXmlConverter.toXml(json.address?.streetNumber)]
                : []
            },
            {
              type: 'element',
              name: 'PCode',
              elements: json.address?.zipCode ? [textXmlConverter.toXml(json.address?.zipCode)] : []
            },
            {
              type: 'element',
              name: 'City',
              elements: json.address?.city ? [textXmlConverter.toXml(json.address?.city)] : []
            },
            {
              type: 'element',
              name: 'Country',
              elements: json.address?.country ? [textXmlConverter.toXml(json.address?.country)] : []
            }
          ]
        }
      ]
    };
  }
}

export class XmlGAEBAddTextConverter extends XmlConverter<GAEB83['award']['addText']> {
  toJson(xml: XmlElement): GAEB83['award']['addText'] {
    const addTextElements =
      xml.elements?.filter(
        (element): element is XmlElement => element.type === 'element' && element.name === 'AddText'
      ) || [];

    if (addTextElements.length === 0) {
      return {
        sections: []
      };
    }

    const sections = addTextElements.map(addTextElement => {
      const outlineAddText = findXmlElementByName(addTextElement.elements, 'OutlineAddText');
      const detailAddText = findXmlElementByName(addTextElement.elements, 'DetailAddText');

      // Preserve the raw HTML content
      const outlineHtml =
        outlineAddText?.elements
          ?.map(el => (typeof el === 'object' ? json2xml(JSON.stringify(el)) : el))
          .join('') || '';

      const detailHtml =
        detailAddText?.elements
          ?.map(el => (typeof el === 'object' ? json2xml(JSON.stringify(el)) : el))
          .join('') || '';

      return {
        outline: outlineHtml,
        detail: detailHtml
      };
    });

    return {
      sections
    };
  }

  toXml(json: GAEB83['award']['addText']): XmlElement {
    // If no sections, return empty AddText element
    if (!json.sections || json.sections.length === 0) {
      return {
        type: 'element' as const,
        name: 'AddText',
        elements: []
      };
    }

    // Create an array of AddText elements for each section
    const addTextElements = json.sections.map(section => {
      // Parse the HTML content back to XML elements
      let outlineElements: XmlNode[] = [];
      try {
        if (section.outline) {
          const parsed = JSON.parse(
            section.outline.startsWith('[') ? section.outline : `[${section.outline}]`
          );
          outlineElements = Array.isArray(parsed) ? parsed : [];
        }
      } catch (e) {
        // If parsing fails, use as-is
        outlineElements = section.outline
          ? [
              {
                type: 'text' as const,
                text: section.outline
              }
            ]
          : [];
      }

      let detailElements: XmlNode[] = [];
      try {
        if (section.detail) {
          const parsed = JSON.parse(
            section.detail.startsWith('[') ? section.detail : `[${section.detail}]`
          );
          detailElements = Array.isArray(parsed) ? parsed : [];
        }
      } catch (e) {
        // If parsing fails, use as-is
        detailElements = section.detail
          ? [
              {
                type: 'text' as const,
                text: section.detail
              }
            ]
          : [];
      }

      return {
        type: 'element' as const,
        name: 'AddText',
        elements: [
          {
            type: 'element' as const,
            name: 'OutlineAddText',
            elements: outlineElements
          },
          {
            type: 'element' as const,
            name: 'DetailAddText',
            elements: detailElements
          }
        ]
      };
    });

    // Always return a valid XmlElement
    return (
      addTextElements[0] || {
        type: 'element' as const,
        name: 'AddText',
        elements: []
      }
    );
  }
}

export class XmlGAEBBoqConverter extends XmlConverter<GAEB83['award']['boq']> {
  toJson(xml: XmlElement): GAEB83['award']['boq'] {
    const boq = findXmlElementByName(xml.elements, 'BoQ');
    if (!boq) {
      return {
        info: {
          date: new Date(),
          label: '',
          name: '',
          hierarchies: []
        },
        body: {
          categories: []
        }
      };
    }

    const boqInfoConverter = new XmlGAEBBoqInfoConverter();
    const info = boqInfoConverter.toJson(boq);

    const boqBodyConverter = new XmlGAEBBoqBodyConverter();
    const boqBody = findXmlElementByName(boq.elements, 'BoQBody');
    const body = boqBody ? boqBodyConverter.toJson(boqBody) : { categories: [] };

    return {
      info,
      body
    };
  }

  toXml(json: GAEB83['award']['boq']): XmlElement {
    const boqInfoConverter = new XmlGAEBBoqInfoConverter();
    const info = boqInfoConverter.toXml(json.info);

    const boqBodyConverter = new XmlGAEBBoqBodyConverter();
    const body = boqBodyConverter.toXml(json.body);

    return {
      type: 'element' as const,
      name: 'BoQ',
      elements: [info, body]
    };
  }
}

export class XmlGAEBBoqInfoConverter extends XmlConverter<GAEB83['award']['boq']['info']> {
  toJson(xml: XmlElement): GAEB83['award']['boq']['info'] {
    const boqInfo = findXmlElementByName(xml.elements, 'BoQInfo');
    if (!boqInfo) {
      return {
        date: new Date(),
        label: '',
        name: '',
        hierarchies: []
      };
    }

    const name = findXmlElementByName(boqInfo.elements, 'Name');
    const label = findXmlElementByName(boqInfo.elements, 'LblBoQ');
    const date = findXmlElementByName(boqInfo.elements, 'Date');

    // Process BoQBkdn elements (hierarchies)
    const boqBkdnElements =
      boqInfo.elements?.filter(
        (element): element is XmlElement => element.type === 'element' && element.name === 'BoQBkdn'
      ) || [];

    const hierarchies = boqBkdnElements.map(bkdn => {
      const type = findXmlElementByName(bkdn.elements, 'Type');
      const label = findXmlElementByName(bkdn.elements, 'LblBoQBkdn');
      const length = findXmlElementByName(bkdn.elements, 'Length');
      const isNumber = findXmlElementByName(bkdn.elements, 'Num');
      const alignment = findXmlElementByName(bkdn.elements, 'Alignment');

      const typeValue = textXmlConverter.toJson(type) || '';
      const isCategory = typeValue === 'BoQLevel';
      const isIndex = typeValue === 'Index';

      return {
        label: textXmlConverter.toJson(label) || '',
        type: isCategory ? ('category' as const) : isIndex ? ('index' as const) : ('item' as const),
        length: parseInt(textXmlConverter.toJson(length) || '0', 10),
        isNumber: textXmlConverter.toJson(isNumber) === 'Yes',
        alignment: textXmlConverter.toJson(alignment) as 'left' | 'right' | 'center' | undefined
      };
    });

    const dateString = textXmlConverter.toJson(date);

    return {
      name: textXmlConverter.toJson(name) || '',
      label: textXmlConverter.toJson(label) || '',
      date: dateString ? new Date(dateString) : new Date(),
      hierarchies
    };
  }

  toXml(json: GAEB83['award']['boq']['info']): XmlElement {
    const hierarchyElements = json.hierarchies.map(hierarchy => {
      return {
        type: 'element' as const,
        name: 'BoQBkdn',
        elements: [
          {
            type: 'element' as const,
            name: 'Type',
            elements: [
              textXmlConverter.toXml(
                hierarchy.type === 'category'
                  ? 'BoQLevel'
                  : hierarchy.type === 'index'
                    ? 'Index'
                    : 'Item'
              )
            ]
          },
          hierarchy.label
            ? {
                type: 'element' as const,
                name: 'LblBoQBkdn',
                elements: [textXmlConverter.toXml(hierarchy.label)]
              }
            : undefined,
          {
            type: 'element' as const,
            name: 'Length',
            elements: [textXmlConverter.toXml(hierarchy.length.toString())]
          },
          {
            type: 'element' as const,
            name: 'Num',
            elements: [textXmlConverter.toXml(hierarchy.isNumber ? 'Yes' : 'No')]
          },
          hierarchy.alignment
            ? {
                type: 'element' as const,
                name: 'Alignment',
                elements: [textXmlConverter.toXml(hierarchy.alignment)]
              }
            : undefined
        ].filter(Boolean) as XmlElement[]
      };
    });

    return {
      type: 'element' as const,
      name: 'BoQInfo',
      elements: [
        {
          type: 'element' as const,
          name: 'Name',
          elements: [textXmlConverter.toXml(json.name)]
        },
        {
          type: 'element' as const,
          name: 'LblBoQ',
          elements: [textXmlConverter.toXml(json.label)]
        },
        {
          type: 'element' as const,
          name: 'Date',
          elements: [textXmlConverter.toXml(json.date.toISOString().split('T')[0])]
        },
        ...hierarchyElements
      ]
    };
  }
}

export class XmlGAEBBoqBodyConverter extends XmlConverter<GAEB83['award']['boq']['body']> {
  toJson(xml: XmlElement): GAEB83['award']['boq']['body'] {
    const categoryConverter = new XmlGAEBBoqCategoryConverter();

    // Find all top-level categories
    const categoryElements =
      xml.elements?.filter(
        (element): element is XmlElement => element.type === 'element' && element.name === 'BoQCtgy'
      ) || [];

    const categories = categoryElements.map(element => categoryConverter.toJson(element));

    return {
      categories
    };
  }

  toXml(json: GAEB83['award']['boq']['body']): XmlElement {
    const categoryConverter = new XmlGAEBBoqCategoryConverter();

    return {
      type: 'element' as const,
      name: 'BoQBody',
      elements: json.categories.map(category => categoryConverter.toXml(category))
    };
  }
}

export class XmlGAEBBoqCategoryConverter extends XmlConverter<GaebBoqCategory> {
  toJson(xml: XmlElement): GaebBoqCategory {
    // Extract the category label
    const labelElement = findXmlElementByName(xml.elements, 'LblTx');

    // Initialize with default values
    let label = '';

    // Extract text from label element, converting any HTML content to formatted string
    if (labelElement && labelElement.elements) {
      // Convert the entire label element content to string, then format it
      label = htmlToFormattedString(
        cleanGaebString(
          labelElement.elements
            .map(el => (typeof el === 'object' ? json2xml(JSON.stringify(el)) : el))
            .join('')
        )
      );
    }

    // Process body element
    const boqBody = findXmlElementByName(xml.elements, 'BoQBody');

    // Initialize subcategories and items
    const categories: GaebBoqCategory[] = [];
    const items: GaebBoqItem[] = [];

    if (boqBody) {
      // Process subcategories recursively
      const subcategoryElements =
        boqBody.elements?.filter(
          (element): element is XmlElement =>
            element.type === 'element' && element.name === 'BoQCtgy'
        ) || [];

      categories.push(...subcategoryElements.map(element => this.toJson(element)));

      // Process items
      const itemlistElement = findXmlElementByName(boqBody.elements, 'Itemlist');
      if (itemlistElement) {
        const itemConverter = new XmlGAEBBoqItemConverter();
        const itemElements =
          itemlistElement.elements?.filter(
            (element): element is XmlElement =>
              element.type === 'element' && (element.name === 'Item' || element.name === 'Remark')
          ) || [];

        items.push(...itemElements.map(element => itemConverter.toJson(element)));
      }
    }

    return {
      referenceNumberPart: xml.attributes?.RNoPart || '',
      referenceNumber: '', // Adding this at the end
      label,
      categories,
      items
    };
  }

  toXml(json: GaebBoqCategory): XmlElement {
    const categoryConverter = new XmlGAEBBoqCategoryConverter();
    const itemConverter = new XmlGAEBBoqItemConverter();

    // Create items list if there are any
    let itemlistElement: XmlElement | undefined;
    if (json.items.length > 0) {
      itemlistElement = {
        type: 'element' as const,
        name: 'Itemlist',
        elements: json.items.map((item: GaebBoqItem) => itemConverter.toXml(item))
      };
    }

    // Create category body with subcategories and items
    const bodyElements: XmlElement[] = [];

    // Add subcategories
    if (json.categories.length > 0) {
      bodyElements.push(
        ...json.categories.map((category: GaebBoqCategory) => categoryConverter.toXml(category))
      );
    }

    // Add itemlist if exists
    if (itemlistElement) {
      bodyElements.push(itemlistElement);
    }

    // Create body element if there are any contents
    const boqBodyElement =
      bodyElements.length > 0
        ? {
            type: 'element' as const,
            name: 'BoQBody',
            elements: bodyElements
          }
        : undefined;

    // Create label text element with HTML structure
    const labelTextElement: XmlElement = {
      type: 'element' as const,
      name: 'LblTx',
      elements: [
        {
          type: 'element' as const,
          name: 'p',
          attributes: {
            style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
          },
          elements: [
            {
              type: 'element' as const,
              name: 'span',
              elements: [
                {
                  type: 'text' as const,
                  text: json.label
                }
              ]
            }
          ]
        }
      ]
    };

    // Build complete category element
    const elements: XmlElement[] = [labelTextElement];
    if (boqBodyElement) {
      elements.push(boqBodyElement);
    }

    return {
      type: 'element' as const,
      name: 'BoQCtgy',
      attributes: {
        ID: json.label ? `CTGY_${json.label.substring(0, 8).replace(/\s+/g, '_')}` : 'CTGY_DEFAULT',
        RNoPart: json.referenceNumberPart
      },
      elements
    };
  }
}

export class XmlGAEBBoqItemConverter extends XmlConverter<GaebBoqItem> {
  toJson(xml: XmlElement): GaebBoqItem {
    // Check if this is a standalone Remark element
    if (xml.name === 'Remark') {
      const descriptionElement = findXmlElementByName(xml.elements, 'Description');
      let description = '';
      let label = '';

      if (descriptionElement) {
        const completeTextElement = findXmlElementByName(
          descriptionElement.elements,
          'CompleteText'
        );
        if (completeTextElement) {
          // Get the outline text for the label
          const outlineTextElement = findXmlElementByName(
            completeTextElement.elements,
            'OutlineText'
          );
          if (outlineTextElement) {
            const outlTxtElement = findXmlElementByName(outlineTextElement.elements, 'OutlTxt');
            if (outlTxtElement) {
              const textOutlTxtElement = findXmlElementByName(
                outlTxtElement.elements,
                'TextOutlTxt'
              );
              label = htmlToFormattedString(
                cleanGaebString(
                  textOutlTxtElement ? json2xml(JSON.stringify(textOutlTxtElement)) : ''
                )
              );
            }
          }

          // Get the detail text for the description
          const detailTxtElement = findXmlElementByName(completeTextElement.elements, 'DetailTxt');
          if (detailTxtElement) {
            description =
              detailTxtElement.type === 'element' ? json2xml(JSON.stringify(detailTxtElement)) : '';
          }
        }
      }

      return {
        type: 'remark',
        label: label || xml.attributes?.ID || '',
        description
      };
    }

    // Check if this is a lump sum item
    const lumpSumElement = findXmlElementByName(xml.elements, 'LumpSumItem');
    const isLumpSum = textXmlConverter.toJson(lumpSumElement) === 'Yes';

    // Get quantity and unit
    const qtyElement = findXmlElementByName(xml.elements, 'Qty');
    const quantity = qtyElement ? parseFloat(textXmlConverter.toJson(qtyElement) || '0') : 0;

    const quElement = findXmlElementByName(xml.elements, 'QU');
    const unit = textXmlConverter.toJson(quElement) || null;

    // Get description
    const descriptionElement = findXmlElementByName(xml.elements, 'Description');
    let description = '';
    let label = '';

    if (descriptionElement) {
      // First check for OutlineText directly in Description (without CompleteText)
      let outlineTextElement = findXmlElementByName(descriptionElement.elements, 'OutlineText');

      // Check for CompleteText structure
      const completeTextElement = findXmlElementByName(descriptionElement.elements, 'CompleteText');
      if (completeTextElement) {
        const detailTxtElement = findXmlElementByName(completeTextElement.elements, 'DetailTxt');
        if (detailTxtElement) {
          description =
            detailTxtElement.type === 'element' ? json2xml(JSON.stringify(detailTxtElement)) : '';
        }

        // If we didn't find OutlineText directly, look in CompleteText
        if (!outlineTextElement) {
          outlineTextElement = findXmlElementByName(completeTextElement.elements, 'OutlineText');
        }
      }

      // Process OutlineText if found (either directly or in CompleteText)
      if (outlineTextElement) {
        // Extract label from OutlineText > OutlTxt > TextOutlTxt structure
        const outlTxtElement = findXmlElementByName(outlineTextElement.elements, 'OutlTxt');
        if (outlTxtElement) {
          const textOutlTxtElement = findXmlElementByName(outlTxtElement.elements, 'TextOutlTxt');
          label = htmlToFormattedString(
            cleanGaebString(textOutlTxtElement ? json2xml(JSON.stringify(textOutlTxtElement)) : '')
          );
        }
      }
    }

    // If there's a unit, it's a work item, otherwise a remark
    if (unit) {
      return {
        type: 'workItem',
        referenceNumber: '',
        referenceNumberPart: xml.attributes?.RNoPart || '',
        referenceNumberIndex: xml.attributes?.RNoIndex || undefined,
        label,
        description,
        isLumbSum: isLumpSum,
        quantity,
        unit: standardizeUnit(unit) ?? ''
      };
    } else {
      return {
        type: 'remark',
        label: xml.attributes?.RNoPart || '',
        description
      };
    }
  }

  toXml(json: GaebBoqItem): XmlElement {
    if (json.type === 'remark') {
      // Parse the description content which should be a serialized JSON string of XML elements
      let detailTxtElements: XmlNode[] = [];
      try {
        detailTxtElements = JSON.parse(json.description);
        if (!Array.isArray(detailTxtElements)) {
          detailTxtElements = [detailTxtElements as unknown as XmlNode];
        }
      } catch (e) {
        detailTxtElements = [
          {
            type: 'element' as const,
            name: 'Text',
            elements: [
              {
                type: 'element' as const,
                name: 'p',
                attributes: {
                  style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
                },
                elements: [
                  {
                    type: 'element' as const,
                    name: 'span',
                    elements: [
                      {
                        type: 'text' as const,
                        text: json.description
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ];
      }

      return {
        type: 'element' as const,
        name: 'Remark',
        attributes: {
          ID: json.label
        },
        elements: [
          {
            type: 'element' as const,
            name: 'Description',
            elements: [
              {
                type: 'element' as const,
                name: 'CompleteText',
                elements: [
                  {
                    type: 'element' as const,
                    name: 'DetailTxt',
                    elements: detailTxtElements
                  },
                  {
                    type: 'element' as const,
                    name: 'OutlineText',
                    elements: [
                      {
                        type: 'element' as const,
                        name: 'OutlTxt',
                        elements: [
                          {
                            type: 'element' as const,
                            name: 'TextOutlTxt',
                            elements: [
                              {
                                type: 'element' as const,
                                name: 'p',
                                attributes: {
                                  style:
                                    'width:481pt;margin-top:0pt;margin-bottom:0pt;font-size:10pt;'
                                },
                                elements: [
                                  {
                                    type: 'element' as const,
                                    name: 'span',
                                    attributes: {
                                      style: 'font-family:Arial;font-size:10pt;'
                                    },
                                    elements: [
                                      {
                                        type: 'text' as const,
                                        text: json.label
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      };
    }

    // Common elements for work items
    const elements: XmlElement[] = [];

    if (json.type === 'workItem') {
      // Add lump sum indicator
      if (json.isLumbSum) {
        elements.push({
          type: 'element' as const,
          name: 'LumpSumItem',
          elements: [{ type: 'text' as const, text: 'Yes' }]
        });
      }

      // Add quantity
      elements.push({
        type: 'element' as const,
        name: 'Qty',
        elements: [{ type: 'text' as const, text: json.quantity.toFixed(3) }]
      });

      // Add unit
      elements.push({
        type: 'element' as const,
        name: 'QU',
        elements: [{ type: 'text' as const, text: json.unit }]
      });
    }

    // Parse the description content which should be a serialized JSON string of XML elements
    let detailTxtElements: XmlNode[] = [];
    try {
      detailTxtElements = JSON.parse(json.description);
      // If it's not an array, wrap it in an array
      if (!Array.isArray(detailTxtElements)) {
        detailTxtElements = [detailTxtElements as unknown as XmlNode];
      }
    } catch (e) {
      // If parsing fails, create a simple text element
      detailTxtElements = [
        {
          type: 'element' as const,
          name: 'Text',
          elements: [
            {
              type: 'element' as const,
              name: 'p',
              attributes: {
                style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
              },
              elements: [
                {
                  type: 'element' as const,
                  name: 'span',
                  elements: [
                    {
                      type: 'text' as const,
                      text: json.description
                    }
                  ]
                }
              ]
            }
          ]
        }
      ];
    }

    elements.push({
      type: 'element' as const,
      name: 'Description',
      attributes: {},
      elements: [
        {
          type: 'element' as const,
          name: 'CompleteText',
          elements: [
            {
              type: 'element' as const,
              name: 'DetailTxt',
              elements: detailTxtElements
            },
            {
              type: 'element' as const,
              name: 'OutlineText',
              elements: [
                {
                  type: 'element' as const,
                  name: 'OutlTxt',
                  elements: [
                    {
                      type: 'element' as const,
                      name: 'TextOutlTxt',
                      elements: [
                        {
                          type: 'element' as const,
                          name: 'p',
                          attributes: {
                            style: 'width:481pt;margin-top:0pt;margin-bottom:0pt;font-size:10pt;'
                          },
                          elements: [
                            {
                              type: 'element' as const,
                              name: 'span',
                              attributes: {
                                style: 'font-family:Arial;font-size:10pt;'
                              },
                              elements: [
                                {
                                  type: 'text' as const,
                                  text: json.label
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    });

    return {
      type: 'element' as const,
      name: 'Item',
      attributes: {
        RNoPart: json.type === 'workItem' ? json.referenceNumberPart : '',
        RNoIndex: json.type === 'workItem' ? json.referenceNumberIndex : ''
      },
      elements
    };
  }
}

export const addReferenceNumbersToGaeb = (gaeb: GAEB83) => {
  let isValidReferenceNumber = true;

  const isValidFormat = (refNumber: string) => {
    const parts = refNumber.split('.');

    if (gaeb.award.boq.info.hierarchies.some(hierarchy => hierarchy.type === 'index')) {
      parts.pop();
    }

    // Check if any part has leading zeros but not all parts do
    const hasLeadingZeros = parts.some(part => part.startsWith('0') && part.length > 1);
    const allHaveLeadingZeros = parts.every(part => part.startsWith('0') && part.length > 1);

    // If some parts have leading zeros, all must have them
    if (hasLeadingZeros && !allHaveLeadingZeros) {
      return false;
    }

    return true;
  };

  const calculateCategoryReferenceNumber = (
    category: GaebBoqCategory,
    parentParts: string[] = [],
    stripLeadingZeroes = false
  ) => {
    const currentParts = [
      ...parentParts,
      removeLeadingZeroes(category.referenceNumberPart, stripLeadingZeroes)
    ];

    // Calculate reference number for the category itself
    category.referenceNumber = createReferenceNumber({
      boqHierarchies: gaeb.award.boq.info.hierarchies,
      categoryParts: currentParts
    });

    // Calculate reference numbers for all items in this category
    category.items.forEach(item => {
      if (item.type === 'workItem') {
        item.referenceNumber = createReferenceNumber({
          boqHierarchies: gaeb.award.boq.info.hierarchies,
          categoryParts: currentParts,
          itemPart: removeLeadingZeroes(item.referenceNumberPart, stripLeadingZeroes),
          itemIndex: item.referenceNumberIndex
            ? removeLeadingZeroes(item.referenceNumberIndex, stripLeadingZeroes)
            : undefined
        });

        isValidReferenceNumber = isValidFormat(item.referenceNumber);
      }
    });

    // Recursively process subcategories
    category.categories.forEach(subcategory => {
      calculateCategoryReferenceNumber(subcategory, currentParts, stripLeadingZeroes);
    });
  };

  // Start the recursive calculation from top-level categories
  gaeb.award.boq.body.categories.forEach(category => {
    calculateCategoryReferenceNumber(category);
  });

  if (!isValidReferenceNumber) {
    gaeb.award.boq.body.categories.forEach(category => {
      calculateCategoryReferenceNumber(category, [], true);
    });
  }
};
