import { json2xml, xml2json } from 'xml-js';
import { logger } from '../../../logger';
import {
  COST_CALCULATION_TABLE_FIELDS,
  CostCalculationTableField,
  CostCalculationTypeTableFieldSelectOptionId,
  WORK_ITEM_TABLE_FIELDS,
  WorkItemTableField,
  WorkItemTableTypeFieldSelectOptionId
} from '../../../types/data-converter';
import { ToUploadFile } from '../../../types/files';
import { ExportTable, ExportTableField, TableRowDto } from '../../../types/table';
import { removeNullish } from '../../../types/utils';
import { rowValueToString } from '../../table';
import { XmlWBSItemEstDetailsConverter } from './converter';
import { getITwoXmlTemplate, getWbsElementTemplate } from './template';
import { WBSItemEstDetailsItem } from './types/cost-estimation';
import { getITwoCostCalculationConvertedRefrenceNumber } from './utils';

export const createITwoXmlFile = ({
  table,
  fileName,
  project,
  estimate,
  options
}: {
  table: ExportTable;
  fileName: string;
  project: {
    name: string;
    description: string | null;
  };
  estimate: {
    currency: string;
  };
  options?: {
    doNotIncludeWorkItemsWithNoCostCalculation?: boolean;
  };
}): ToUploadFile => {
  const iTwoXmlString = getITwoXmlTemplate({
    project,
    estimate,
    wbsElementsXmlString: getWbsXmlString(table, options)
  });

  return {
    // Formatting the xml to be more readable
    content: json2xml(xml2json(iTwoXmlString, { compact: false, spaces: 2 }), {
      compact: false,
      spaces: 2
    }),
    name: fileName,
    extension: 'xml'
  };
};

const getWbsXmlString = (
  table: ExportTable,
  options?: {
    doNotIncludeWorkItemsWithNoCostCalculation?: boolean;
  }
) => {
  const fieldReferenceIdToFieldId = table.fields.reduce(
    (acc, field) => {
      if (field.referenceId && field.referenceId in WORK_ITEM_TABLE_FIELDS) {
        acc[field.referenceId as WorkItemTableField] = field.id;
      }
      return acc;
    },
    {} as Record<WorkItemTableField, string>
  );
  const boqRows = table.rows.filter(
    row => row[fieldReferenceIdToFieldId['type']] === WorkItemTableTypeFieldSelectOptionId.BoQ
  );
  const wbsElements = boqRows.map(row => {
    const name = rowValueToString(row[fieldReferenceIdToFieldId['shortText']]);
    const description = rowValueToString(row[fieldReferenceIdToFieldId['longText']]);

    return getWbsElementTemplate({
      name,
      description,
      itemsXmlString: getWbsItemsXmlString(table, name, fieldReferenceIdToFieldId, options)
    });
  });

  return wbsElements.join('\n');
};

const getWbsItemsXmlString = (
  table: ExportTable,
  boqName: string,
  fieldReferenceIdToFieldId: Record<WorkItemTableField, string>,
  options?: {
    doNotIncludeWorkItemsWithNoCostCalculation?: boolean;
  }
) => {
  const childTable = table.childTable;
  if (!childTable) {
    logger.error(`Table ${table.name} has no child table`);
    throw new Error(`Table ${table.name} has no child table`);
  }

  const wbsItems: string[] = table.rows
    .filter(
      row =>
        row[fieldReferenceIdToFieldId['boqName']] === boqName &&
        row[fieldReferenceIdToFieldId['type']] === WorkItemTableTypeFieldSelectOptionId.WorkItem
    )
    .map(row => {
      const referenceNumber = row[fieldReferenceIdToFieldId['referenceNumber']] as string;
      const name = row[fieldReferenceIdToFieldId['shortText']] as string;
      const costCalculationFields = childTable.fields;
      const costCalculationRows = childTable.rows.filter(
        childRow => childRow.parentTableRowId === row.id
      );

      if (options?.doNotIncludeWorkItemsWithNoCostCalculation && costCalculationRows.length === 0) {
        return '';
      }

      return getWbsItemXmlString({
        referenceNumber,
        name,
        wbsItemEstDetails: convertRowsToWBSItemEstDetails(
          costCalculationFields,
          costCalculationRows,
          null,
          ''
        )
      });
    });

  return wbsItems.join('\n');
};

const getWbsItemXmlString = (item: {
  referenceNumber: string;
  name: string;
  wbsItemEstDetails: WBSItemEstDetailsItem[];
}): string => {
  const xmlWBSItemEstDetailsConverter = new XmlWBSItemEstDetailsConverter();
  const estDetailsXml = xmlWBSItemEstDetailsConverter.toXml({
    items: item.wbsItemEstDetails
  });
  const estDetailsXmlString = json2xml(JSON.stringify(estDetailsXml), {
    compact: false,
    spaces: 2
  });

  return `<WBSItem>
  <NameWBSItem>${item.referenceNumber}</NameWBSItem>
  <OutlineSpecs>${item.name}</OutlineSpecs>
  <IsNeutralisedCostRisk>0</IsNeutralisedCostRisk>
  <UsesDerivedCoCs>0</UsesDerivedCoCs>
  <IsRevenueDeduction>0</IsRevenueDeduction>
  <IsFixedPriceItem>0</IsFixedPriceItem>
  <IsNoMarkup>0</IsNoMarkup>
  <URFromSubDesc>0</URFromSubDesc>
  <ByManualCharge_E>0</ByManualCharge_E>
  <AdvancedAllocation_E>0</AdvancedAllocation_E>
  <SPPhase>Tender</SPPhase>
  <EstDetails>
    ${estDetailsXmlString}
  </EstDetails>
</WBSItem>`;
};

const convertRowsToWBSItemEstDetails = (
  fields: ExportTableField[],
  rows: TableRowDto[],
  parentRowId: string | null,
  parentReferenceNumber: string
): WBSItemEstDetailsItem[] => {
  const rowsMatchingParentId = rows.filter(row => row.parentRowId === parentRowId);

  if (rowsMatchingParentId.length === 0) {
    return [];
  }

  const referenceIdToFieldId = fields.reduce(
    (acc, field) => {
      if (field.referenceId && field.referenceId in COST_CALCULATION_TABLE_FIELDS) {
        acc[field.referenceId as CostCalculationTableField] = field.id;
      }
      return acc;
    },
    {} as Record<CostCalculationTableField, string>
  );

  let referernceNumberPart = 0;

  return rowsMatchingParentId
    .map((row): WBSItemEstDetailsItem | undefined => {
      const type = row[referenceIdToFieldId['type']] as CostCalculationTypeTableFieldSelectOptionId;
      if (type === 'CoCDetail') {
        return {
          type: 'CoCDetail' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          urValue: row[referenceIdToFieldId['costPerUnit']] as number | undefined,
          qFactorCoc: row[referenceIdToFieldId['qFactorCoc']] as number | undefined,
          cFactorCoc: row[referenceIdToFieldId['cFactorCoc']] as number | undefined,
          currency: row[referenceIdToFieldId['currency']] as string | undefined,
          key: row[referenceIdToFieldId['key']] as string | undefined,
          name: row[referenceIdToFieldId['name']] as string | undefined,
          identifyKey: row[referenceIdToFieldId['identifyKey']] as string | undefined,
          isDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'SubItem') {
        referernceNumberPart++;
        const convertedRefrenceNumber =
          getITwoCostCalculationConvertedRefrenceNumber(referernceNumberPart);
        return {
          type: 'SubItem' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          compressed: row[referenceIdToFieldId['compressed']] as boolean | undefined,
          estDetails: {
            items: convertRowsToWBSItemEstDetails(
              fields,
              rows,
              row.id,
              `${parentReferenceNumber}${convertedRefrenceNumber}`
            )
          },
          sItemDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          sItemLSum: row[referenceIdToFieldId['sItemLSum']] as string | undefined,
          text: row[referenceIdToFieldId['name']] as string | undefined,
          unitOfMeasure: row[referenceIdToFieldId['unit']] as string | undefined,
          subItemNumber: `${parentReferenceNumber}${convertedRefrenceNumber}`,
          sItemLSumAbs: row[referenceIdToFieldId['sItemLSumAbs']] as string | undefined,
          sItemNo: `${convertedRefrenceNumber}`,
          sItemReserve: row[referenceIdToFieldId['sItemReserve']] as string | undefined,
          spPhase: row[referenceIdToFieldId['spPhase']] as string | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'EstTextElement') {
        return {
          type: 'EstTextElement' as const,
          text: row[referenceIdToFieldId['name']] as string | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'EstCalcElement') {
        return {
          type: 'EstCalcElement' as const,
          formula: row[referenceIdToFieldId['name']] as string | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'CommodityDetail') {
        referernceNumberPart++;
        const convertedRefrenceNumber =
          getITwoCostCalculationConvertedRefrenceNumber(referernceNumberPart);
        return {
          type: 'CommodityDetail' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          currency: row[referenceIdToFieldId['currency']] as string | undefined,
          identifyKey: row[referenceIdToFieldId['identifyKey']] as string | undefined,
          isDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          nameCommodity: row[referenceIdToFieldId['key']] as string | undefined,
          descrCommodity: row[referenceIdToFieldId['name']] as string | undefined,
          urValue: row[referenceIdToFieldId['costPerUnit']] as number | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          estDetails: {
            items: convertRowsToWBSItemEstDetails(
              fields,
              rows,
              row.id,
              `${parentReferenceNumber}${convertedRefrenceNumber}`
            )
          },
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'AssemblyDetail') {
        referernceNumberPart++;
        const convertedRefrenceNumber =
          getITwoCostCalculationConvertedRefrenceNumber(referernceNumberPart);
        return {
          type: 'AssemblyDetail' as const,
          nameAssembly: row[referenceIdToFieldId['key']] as string | undefined,
          descrAssembly: row[referenceIdToFieldId['name']] as string | undefined,
          unitOfMeasure: row[referenceIdToFieldId['unit']] as string | undefined,
          identifyKey: row[referenceIdToFieldId['identifyKey']] as string | undefined,
          isDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          estDetails: {
            items: convertRowsToWBSItemEstDetails(
              fields,
              rows,
              row.id,
              `${parentReferenceNumber}${convertedRefrenceNumber}`
            )
          },
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      }

      return undefined;
    })
    .filter(removeNullish);
};
