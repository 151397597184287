import { I18n } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { EnumMap } from '../utils';

export type TaskAvailabilityScope = 'EVERYWHERE' | 'OUTSIDE_PROJECT' | 'PROJECT';

export const taskReferenceIdSchema = z.enum([
  'COST_ESTIMATOR_CREATE_PROJECT_FROM_I_TWO_PROJECT',
  'COST_ESTIMATOR_CREATE_TEMPLATE_PROJECT_FROM_ITWO_PROJECT',
  'COST_ESTIMATOR_ADD_COST_CALCULATION_TO_PROJECT_WORK_ITEMS',
  'COST_ESTIMATOR_REIMPORT_I_TWO_PROJECT',
  'TENDER_ADMINISTRATOR_CREATE_PROJECT',
  'TENDER_ADMINISTRATOR_IDENTIFY_OPPORTUNITIES',
  'TENDER_ADMINISTRATOR_GET_OFFERS_FROM_SUB_CONTRACTORS'
]);

export type TaskReferenceId = z.infer<typeof taskReferenceIdSchema>;

export const AGENT_TASK_MAP: EnumMap<
  TaskReferenceId,
  { getDescription: (t: I18n['_']) => string }
> = {
  COST_ESTIMATOR_CREATE_PROJECT_FROM_I_TWO_PROJECT: {
    id: 'COST_ESTIMATOR_CREATE_PROJECT_FROM_I_TWO_PROJECT',
    getLabel: t => t(msg`Create project`),
    getDescription: t => t(msg`Creates a new project from X83 files.`)
  },
  COST_ESTIMATOR_CREATE_TEMPLATE_PROJECT_FROM_ITWO_PROJECT: {
    id: 'COST_ESTIMATOR_CREATE_TEMPLATE_PROJECT_FROM_ITWO_PROJECT',
    getLabel: t => t(msg`Create template project`),
    getDescription: t => t(msg`Creates a new template project from an iTWO XML and X83 files.`)
  },
  COST_ESTIMATOR_REIMPORT_I_TWO_PROJECT: {
    id: 'COST_ESTIMATOR_REIMPORT_I_TWO_PROJECT',
    getLabel: t => t(msg`Reimport iTWO project`),
    getDescription: t => t(msg`Reimports an iTWO project from an XML and X83 files.`)
  },
  COST_ESTIMATOR_ADD_COST_CALCULATION_TO_PROJECT_WORK_ITEMS: {
    id: 'COST_ESTIMATOR_ADD_COST_CALCULATION_TO_PROJECT_WORK_ITEMS',
    getLabel: t => t(msg`Estimate the project work items`),
    getDescription: t => t(msg`Karl estimates the BoQ of the current project.`)
  },
  TENDER_ADMINISTRATOR_CREATE_PROJECT: {
    id: 'TENDER_ADMINISTRATOR_CREATE_PROJECT',
    getLabel: t => t(msg`Create project`),
    getDescription: t => t(msg`Creates a new project.`)
  },
  TENDER_ADMINISTRATOR_IDENTIFY_OPPORTUNITIES: {
    id: 'TENDER_ADMINISTRATOR_IDENTIFY_OPPORTUNITIES',
    getLabel: t => t(msg`Identify opportunities`),
    getDescription: t => t(msg`Identifies opportunities for the company.`)
  },
  TENDER_ADMINISTRATOR_GET_OFFERS_FROM_SUB_CONTRACTORS: {
    id: 'TENDER_ADMINISTRATOR_GET_OFFERS_FROM_SUB_CONTRACTORS',
    getLabel: t => t(msg`Get offers from sub contractors`),
    getDescription: t => t(msg`Gets offers from sub contractors and request offers via email.`)
  }
};
