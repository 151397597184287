import { Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';
import { Controller, FieldValues } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { CheckboxControlProps } from '@ark-ui/react';

export const CheckboxGroupInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  options,
  size = 'md',
  checkboxColor = 'white'
}: FormInputPropsWithoutChildren<TFieldValues> & {
  options: {
    label: string;
    value: string;
  }[];
  checkboxColor?: CheckboxControlProps['color'];
}) => {
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormInput
          form={form}
          name={name}
          root={root}
          label={label}
          size={size}
          errorProps={errorProps}
          hideError={hideError}
          helperProps={helperProps}
          helperText={helperText}
          labelProps={labelProps}
          isRequired={isRequired}
          isDisabled={isDisabled}
        >
          <CheckboxGroup size={size} onValueChange={field.onChange}>
            <Stack gap={0.5}>
              {options.map((option, index) => (
                <Checkbox.Root value={option.value} key={index} size={size}>
                  <Checkbox.Control color={checkboxColor} />
                  <Checkbox.Label>{option.label}</Checkbox.Label>
                </Checkbox.Root>
              ))}
            </Stack>
          </CheckboxGroup>
        </FormInput>
      )}
    />
  );
};

export const CheckboxInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  isReadOnly,
  size = 'md'
}: FormInputPropsWithoutChildren<TFieldValues>) => {
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <Checkbox.Root
            colorPalette={'primary'}
            size={size}
            onCheckedChange={({ checked }) => field.onChange(checked)}
            checked={field.value ?? false}
            disabled={isDisabled}
            required={isRequired}
            cursor={isReadOnly || isDisabled ? 'default' : 'pointer'}
            readOnly={isReadOnly}
          >
            <Checkbox.HiddenInput />
            <Checkbox.Control>
              <Checkbox.Indicator />
            </Checkbox.Control>
            <Checkbox.Label>{label}</Checkbox.Label>
          </Checkbox.Root>
        );
      }}
    />
  );
};
