import { z } from 'zod';
import { addressSchema } from '../address';
import { AgentType } from '../agent';
import { TaskReferenceId } from '../task';

export const userAgentSettingsSchema = z.object({
  tenderAdministrator: z
    .object({
      getOffersFromSubContractors: z
        .object({
          shouldExportAsOnventisXlsx: z.boolean().nullish(),
          fromEmail: z.string().email().nullish()
        })
        .optional()
    })
    .optional(),
  costEstimator: z
    .object({
      createTemplateProjectFromItwoProject: z
        .object({
          defaultProjectList: z.string().nullish()
        })
        .optional(),
      addCostCalculationToProjectWorkItems: z
        .object({
          defaultProjectList: z.string().nullish(),
          minSimilarityPercentageToAutofill: z.number().min(0).max(100).nullish()
        })
        .optional()
    })
    .optional()
});
export type UserAgentSettings = z.infer<typeof userAgentSettingsSchema>;

export const AGENTS_TASK_SETTINGS_MAP: Record<AgentType, { taskReferenceId: TaskReferenceId }[]> = {
  COST_ESTIMATOR: [
    {
      taskReferenceId: 'COST_ESTIMATOR_ADD_COST_CALCULATION_TO_PROJECT_WORK_ITEMS'
    },
    {
      taskReferenceId: 'COST_ESTIMATOR_CREATE_TEMPLATE_PROJECT_FROM_ITWO_PROJECT'
    }
  ],
  CONSTRUCTION_TENDER_ADMINISTRATOR: [
    {
      taskReferenceId: 'TENDER_ADMINISTRATOR_GET_OFFERS_FROM_SUB_CONTRACTORS'
    }
  ]
};

export const getAgentTypesWithTaskSettings = () => {
  return Object.entries(AGENTS_TASK_SETTINGS_MAP)
    .filter(([_, taskSettings]) => taskSettings.length > 0)
    .map(([agentType]) => agentType as AgentType);
};

export const userSettingsDtoSchema = z.object({
  agentSettings: userAgentSettingsSchema.nullable(),
  address: addressSchema.nullable(),
  contactPhoneNumber: z.string().nullable(),
  contactFaxNumber: z.string().nullable(),
  defaultProjectTeamId: z.string().nullable(),
  defaultProjectTagIds: z.array(z.string()).nullable()
});

export type UserSettingsDto = z.infer<typeof userSettingsDtoSchema>;
