import { xml2json } from 'xml-js';
import { XmlDocument, XmlElement } from '../xml-converter';
import { XmlITwoWbsConverter } from './converter';

export const getItwoMajorWbsMapFromXml = (xml: XmlDocument): Record<string, XmlElement> => {
  const estimateRoot = xml.elements?.[0];
  if (estimateRoot?.type === 'element' && estimateRoot.elements) {
    const estimate = estimateRoot.elements.find(
      element => element.type === 'element' && element.name === 'Estimate'
    ) as XmlElement | undefined;
    const wbsList = estimate?.elements?.filter(
      element => element.type === 'element' && element.name === 'WBS'
    ) as XmlElement[];
    const majorWbsList = wbsList.filter(wbs => {
      const wbsTypeElement = wbs.elements?.find(
        element => element.type === 'element' && element.name === 'WBSType'
      );
      return (
        wbsTypeElement?.type === 'element' &&
        wbsTypeElement.elements?.[0]?.type === 'text' &&
        wbsTypeElement.elements[0].text === 'MajorWBS'
      );
    });
    return majorWbsList.reduce(
      (acc, wbs) => {
        const wbsNameElement = wbs.elements?.find(
          element => element.type === 'element' && element.name === 'NameWBS'
        );
        const wbsName =
          wbsNameElement?.type === 'text'
            ? wbsNameElement.text
            : wbsNameElement?.elements?.[0]?.type === 'text'
              ? wbsNameElement.elements[0].text
              : '';

        if (wbsName && wbsName !== '') {
          acc[wbsName] = wbs;
        }
        return acc;
      },
      {} as Record<string, XmlElement>
    );
  }
  return {};
};

export const getItemsFromWbs = (wbs: XmlElement) => {
  const wbsItems = wbs?.elements?.find(
    element => element.type === 'element' && element.name === 'ITEMS'
  ) as XmlElement;

  const xmlItemsConverter = new XmlITwoWbsConverter();
  return xmlItemsConverter.toJson(wbsItems);
};

export const convertXmlStringToXmlElement = (xmlString: string): XmlElement => {
  const rootNode: XmlElement = JSON.parse(xml2json(xmlString, { compact: false, spaces: 2 }));

  if (rootNode.elements?.[0]) {
    return rootNode.elements[0] as XmlElement;
  }

  throw new Error('Invalid XML string');
};

export const getITwoCostCalculationConvertedRefrenceNumber = (referenceNumber: number) => {
  if (referenceNumber <= 9) {
    return referenceNumber.toString();
  }
  // After 9, use A, B, C etc
  const charCode = 'A'.charCodeAt(0) + (referenceNumber - 10);
  return String.fromCharCode(charCode);
};
