import { z } from 'zod';
import { EnumMap } from '../utils';
import { Color } from '../color';

export const emailStatusSchema = z.enum(['SCHEDULED', 'SENT', 'FAILED']);

export type EmailStatus = z.infer<typeof emailStatusSchema>;

export const EMAIL_STATUSES: EnumMap<EmailStatus, { color: Color }> = {
  SCHEDULED: { id: 'SCHEDULED', getLabel: t => t('Scheduled'), color: 'GRAY' },
  SENT: { id: 'SENT', getLabel: t => t('Sent'), color: 'GREEN' },
  FAILED: { id: 'FAILED', getLabel: t => t('Failed'), color: 'RED' }
};
