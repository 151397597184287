import { z } from 'zod';

export const organizationConfigurationSchema = z.object({
  agents: z
    .object({
      costEstimator: z
        .object({
          shouldKeepCostCalculationPrices: z.boolean().optional()
        })
        .optional()
    })
    .optional()
});

export type OrganizationConfiguration = z.infer<typeof organizationConfigurationSchema>;
