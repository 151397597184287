import { TableRowDto, TableRowPrimitiveDataType } from '../../../types';

export const convertToTableRowDto = ({
  id,
  parentRowId,
  nesting_level,
  parentTableRowId,
  projectId,
  order,
  ...restRow
}: Record<string, unknown>): TableRowDto => {
  return {
    id: id as string,
    parentRowId: parentRowId as string | null,
    parentTableRowId: parentTableRowId as string | null,
    order: Number(order),
    projectId: projectId as string | null,
    primaryFieldValue: restRow.primaryFieldValue as TableRowPrimitiveDataType | null,
    proposedChange: null,
    ...restRow
  };
};
