'use client';

import { Switch } from '@company/ui/components';
import { FieldValues } from '@company/ui/hooks/form';
import { Controller } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';

export const SwitchInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  isReadOnly,
  size = 'md'
}: FormInputPropsWithoutChildren<TFieldValues>) => {
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormInput
          form={form}
          name={name}
          root={root}
          label={label}
          size={size}
          errorProps={errorProps}
          hideError={hideError}
          helperProps={helperProps}
          helperText={helperText}
          labelProps={labelProps}
          isRequired={isRequired}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
        >
          <Switch.Root
            size={size}
            onCheckedChange={({ checked }) => field.onChange(checked)}
            checked={field.value}
            colorPalette={'primary'}
          >
            <Switch.HiddenInput />
            <Switch.Control>
              <Switch.Thumb />
            </Switch.Control>
          </Switch.Root>
        </FormInput>
      )}
    />
  );
};
