import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { EnumMap } from '../utils';

export const tableDataTypeSchema = z.enum([
  'I_TWO_BOQ',
  'I_TWO_COST_CALCULATION',
  'CONSTRUCTION_PROJECT_AQUISITION',
  'CONSTRUCTION_SUB_CONTRACTOR_WORK_DESCRIPTION',
  'CONSTRUCTION_SUB_CONTRACTOR_DEFINITION'
]);
export type TableDataType = z.infer<typeof tableDataTypeSchema>;

export const tableDataTypeToExportFormatSchema = z.enum(['CSV', 'EXCEL', 'GAEB_X83']);
export type TableDataTypeToExportFormat = z.infer<typeof tableDataTypeToExportFormatSchema>;

export const TABLE_EXPORT_FORMATS: EnumMap<
  TableDataTypeToExportFormat,
  {
    fileExtension: string;
    mimeType: string;
    requiresAllFields: boolean;
  }
> = {
  CSV: {
    id: 'CSV',
    getLabel: t => t(msg`CSV`),
    fileExtension: 'csv',
    mimeType: 'text/csv',
    requiresAllFields: false
  },
  EXCEL: {
    id: 'EXCEL',
    getLabel: t => t(msg`Excel`),
    fileExtension: 'xlsx',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    requiresAllFields: false
  },
  GAEB_X83: {
    id: 'GAEB_X83',
    getLabel: t => t(msg`GAEB X83`),
    fileExtension: 'x83',
    mimeType: 'application/xml',
    requiresAllFields: true
  }
};

export const tableDataTypeToExportFormats = (
  dataType: TableDataType | null
): TableDataTypeToExportFormat[] => {
  const defaultFormats: TableDataTypeToExportFormat[] = ['CSV', 'EXCEL'];
  switch (dataType) {
    case 'I_TWO_BOQ':
      return [...defaultFormats, 'GAEB_X83'];
    case 'I_TWO_COST_CALCULATION':
      return [...defaultFormats];
    default:
      return defaultFormats;
  }
};
