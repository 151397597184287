'use client';

import { Box, Button, ButtonProps, Stack } from '@chakra-ui/react';
import { Trans } from '@lingui/react/macro';
import { BubbleMenu, Editor } from '@tiptap/react';
import { CellSelection, TableMap } from 'prosemirror-tables';
import React from 'react';

type SelectionType = 'table' | 'row' | 'column' | null;

const findTable = (selection: any): { pos: number; node: any } | null => {
  const { $anchor } = selection;
  for (let depth = $anchor.depth; depth > 0; depth--) {
    const node = $anchor.node(depth);
    if (node.type.spec.tableRole === 'table') {
      return { pos: $anchor.start(depth), node };
    }
  }
  return null;
};

export const TableOperationsMenu = ({ editor }: { editor: Editor | null }) => {
  const [tableCellSelectionType, setTableCellSelectionType] = React.useState<SelectionType>(null);

  React.useEffect(() => {
    if (!editor) return;

    const updateSelectionType = () => {
      const { selection } = editor.state;
      if (!(selection instanceof CellSelection)) {
        setTableCellSelectionType(null);
        return;
      }
      const tableInfo = findTable(selection);
      if (!tableInfo) {
        setTableCellSelectionType(null);
        return;
      }

      const { pos: tablePos, node: tableNode } = tableInfo;
      const tableMap = TableMap.get(tableNode);

      // Use $anchorCell and $headCell instead of $anchor/$head.
      const anchorIndex = selection.$anchorCell.pos - tablePos;
      const headIndex = selection.$headCell.pos - tablePos;
      let rect;
      try {
        rect = tableMap.rectBetween(anchorIndex, headIndex);
      } catch (error) {
        setTableCellSelectionType(null);
        return;
      }

      const isFullRow = rect.left === 0 && rect.right === tableMap.width;
      const isFullColumn = rect.top === 0 && rect.bottom === tableMap.height;
      if (isFullRow && isFullColumn) {
        setTableCellSelectionType('table');
      } else if (isFullRow) {
        setTableCellSelectionType('row');
      } else if (isFullColumn) {
        setTableCellSelectionType('column');
      } else {
        setTableCellSelectionType(null);
      }
    };

    editor.on('selectionUpdate', updateSelectionType);
    return () => {
      editor.off('selectionUpdate', updateSelectionType);
    };
  }, [editor]);

  if (!editor) return null;

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 0,
        placement: 'bottom-start',
        // Disable flip & preventOverflow
        popperOptions: {
          strategy: 'absolute',
          modifiers: [
            { name: 'flip', enabled: false },
            { name: 'preventOverflow', enabled: false }
          ]
        }
      }}
    >
      {tableCellSelectionType !== null && (
        <Box
          rounded={'md'}
          bgColor={'white'}
          w={'auto'}
          shadow={'md'}
          borderWidth={1}
          px={1}
          py={1}
        >
          <Stack gap={0}>
            {tableCellSelectionType === 'column' && (
              <>
                <TableOperationsMenuButton
                  onClick={() => editor.chain().focus().addColumnBefore().run()}
                >
                  <Trans>Insert Column Left</Trans>
                </TableOperationsMenuButton>
                <TableOperationsMenuButton
                  onClick={() => editor.chain().focus().addColumnAfter().run()}
                >
                  <Trans>Insert Column Right</Trans>
                </TableOperationsMenuButton>
                <TableOperationsMenuButton
                  onClick={() => editor.chain().focus().deleteColumn().run()}
                  colorPalette={'red'}
                >
                  <Trans>Delete Column</Trans>
                </TableOperationsMenuButton>
              </>
            )}
            {tableCellSelectionType === 'row' && (
              <>
                <TableOperationsMenuButton
                  onClick={() => editor.chain().focus().addRowBefore().run()}
                >
                  <Trans>Insert Row Above</Trans>
                </TableOperationsMenuButton>
                <TableOperationsMenuButton
                  onClick={() => editor.chain().focus().addRowAfter().run()}
                >
                  <Trans>Insert Row Below</Trans>
                </TableOperationsMenuButton>
                <TableOperationsMenuButton
                  onClick={() => editor.chain().focus().deleteRow().run()}
                  colorPalette={'red'}
                >
                  <Trans>Delete Row</Trans>
                </TableOperationsMenuButton>
              </>
            )}
            {tableCellSelectionType === 'table' && (
              <>
                <TableOperationsMenuButton
                  onClick={() => editor.chain().focus().deleteTable().run()}
                  colorPalette={'red'}
                >
                  <Trans>Delete Table</Trans>
                </TableOperationsMenuButton>
              </>
            )}
          </Stack>
        </Box>
      )}
    </BubbleMenu>
  );
};

const TableOperationsMenuButton = ({ onClick, children, ...props }: ButtonProps) => {
  return (
    <Button
      onClick={onClick}
      size={'xs'}
      variant={'ghost'}
      textAlign={'left'}
      justifyContent={'flex-start'}
      {...props}
    >
      {children}
    </Button>
  );
};
