import { z } from 'zod';
import { projectTypeSchema } from '../project';
import { tagTypeSchema } from '../tag';

//
// Get similar projects
//

export const getSimilarProjectsSchema = z.object({
  projectId: z.string(),
  userId: z.string(),
  limit: z.number().optional(),
  where: z
    .object({
      tags: z
        .object({
          type: tagTypeSchema
        })
        .optional()
    })
    .optional()
});
export type GetSimilarProjectsArgs = z.infer<typeof getSimilarProjectsSchema>;

export const getSimilarProjectsOutputSchema = z.array(
  z.object({
    id: z.string(),
    type: projectTypeSchema,
    name: z.string(),
    description: z.string().nullable(),
    pieceOfInformationId: z.string()
  })
);
export type GetSimilarProjectsOutput = z.infer<typeof getSimilarProjectsOutputSchema>;
