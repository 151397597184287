import { LocaleCodeType } from '@company/common/types';
import { Button, Table } from '..';
import { getMonthName } from './utils';
import { formatDate } from '@company/common/lib';

const monthGrid = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [9, 10, 11]
];

export const MonthSelectionView = ({
  year,
  onSelect,
  options
}: {
  year: number;
  onSelect: (month: number) => void;
  options: {
    locale: LocaleCodeType;
  };
}) => {
  return (
    <Table.Root size={'sm'} unstyled h={'full'} w={'full'}>
      <Table.Body>
        {monthGrid.map((monthRow, weekIndex) => (
          <Table.Row key={weekIndex}>
            {monthRow.map((month, dateIndex) => (
              <Table.Cell
                onClick={() => onSelect(month)}
                fontWeight={'medium'}
                key={dateIndex}
                p={0}
                w={'33.333%'}
              >
                <Button size={'xs'} w={'full'} h={'full'} p={0} variant={'ghost'}>
                  {formatDate(new Date(year, month, 1), options.locale, 'MMM')}
                </Button>
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};
