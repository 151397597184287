import { z } from 'zod';

export const createEmailDraftSchema = z.object({
  subject: z.string(),
  body: z.string(),
  to: z.array(z.string()),
  cc: z.array(z.string()),
  bcc: z.array(z.string()),
  replyTo: z.array(z.string()),
  from: z.string(),
  name: z.string(),
  attachments: z.array(z.object({ fileId: z.string() })),
  isTemplate: z.boolean().optional(),
  projectId: z.string().nullable()
});

export type CreateEmailDraft = z.infer<typeof createEmailDraftSchema>;
