'use client';

import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import React, { useImperativeHandle } from 'react';
import { SelectOption } from '../../../types/select';
import {
  KeyboardShortcutHandler,
  MentionConversationMember,
  MentionPieceOfInformation,
  MentionTask
} from './extensions';
import { suggestion } from './suggestion';
import { SuggestionItemsQuery } from './types';

interface MessageInputEditorHandle {
  focus: () => void;
  submit: () => void;
  updateContent: (content: string) => void;
  appendToContent: (content: string) => void;
}

type MentionProps = {
  query: SuggestionItemsQuery<SelectOption>;
  emptyState: React.ReactNode;
};

interface MessageInputEditorProps {
  editorRef: React.RefObject<MessageInputEditorHandle>;
  content: string;
  setContent: (content: string) => void;
  onSubmit: (content: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  minHeight: string;
  maxHeight: string;
  backgroundColor?: string;
  mentions: {
    conversationMemberMention: MentionProps;
    pieceOfInformationMention: MentionProps;
    taskMention: MentionProps;
  };
}

export const MessageInputEditor = ({
  editorRef,
  content,
  setContent,
  onSubmit,
  placeholder,
  autoFocus,
  minHeight,
  maxHeight,
  mentions,
  backgroundColor
}: MessageInputEditorProps) => {
  const editor = useEditor(
    {
      immediatelyRender: false,
      autofocus: autoFocus,
      editable: true,
      editorProps: {},
      extensions: [
        Document,
        Paragraph,
        Text,
        HardBreak,
        ListItem,
        OrderedList.configure({
          keepAttributes: true,
          keepMarks: true
        }),
        BulletList,
        Blockquote,
        Bold,
        Italic,
        Strike,
        Underline,
        Placeholder.configure({ placeholder }),
        KeyboardShortcutHandler.extend({
          addKeyboardShortcuts() {
            return {
              'Shift-Enter': () => {
                return this.editor.commands.first(({ commands }) => [
                  () => commands.newlineInCode(),
                  () => commands.splitListItem('listItem'),
                  () => commands.createParagraphNear(),
                  () => commands.liftEmptyBlock(),
                  () => commands.splitBlock()
                ]);
              }
            };
          }
        }),
        MentionConversationMember.configure({
          HTMLAttributes: {
            class: 'mention conversation-member-mention'
          },
          suggestion: suggestion({
            char: '@',
            items: mentions.conversationMemberMention.query,
            emptyState: mentions.conversationMemberMention.emptyState
          })
        }),
        MentionPieceOfInformation.configure({
          HTMLAttributes: {
            class: 'mention piece-of-information-mention'
          },
          suggestion: suggestion({
            char: '#',
            items: mentions.pieceOfInformationMention.query,
            emptyState: mentions.pieceOfInformationMention.emptyState
          })
        }),
        MentionTask.configure({
          HTMLAttributes: {
            class: 'mention task-mention'
          },
          suggestion: suggestion({
            char: '!',
            items: mentions.taskMention.query,
            emptyState: mentions.taskMention.emptyState
          })
        })
      ],
      content,
      onUpdate: ({ editor }) => {
        setContent(editor.getHTML());
      }
    },
    []
  );

  const submit = () => {
    editor?.commands.clearContent();
    onSubmit(content ?? '');
    return content ?? '';
  };

  useImperativeHandle<MessageInputEditorHandle, MessageInputEditorHandle>(editorRef, () => ({
    focus: () => {
      editor?.commands.focus() ?? false;
    },
    submit,
    updateContent: (content: string) => {
      editor?.commands.setContent(content);
    },
    appendToContent: (content: string) => {
      const currentContent = editor?.getHTML() ?? '';
      if (currentContent === '<p></p>') {
        editor?.commands.setContent(content);
      } else {
        editor?.commands.setContent(editor.getHTML() + content);
      }
    }
  }));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const isEnterOnly =
      event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.metaKey;

    if (isEnterOnly) {
      submit();
      editor?.commands.clearContent();
    }
  };

  React.useEffect(() => {
    setContent(editor?.getHTML() ?? '');
  }, [editor?.getHTML()]);

  return (
    <EditorContent
      editor={editor}
      onClick={() => editor?.commands.focus()}
      autoFocus
      onKeyDown={handleKeyDown}
      style={{
        overflowY: 'auto',
        width: '100%',
        minHeight: minHeight,
        maxHeight: maxHeight,
        scrollbarWidth: 'none',
        cursor: 'text',
        backgroundColor: backgroundColor
      }}
    />
  );
};
