import { logger } from '@company/common/logger';
import React from 'react';

export const useMeasureFPS = (
  scrollElement: Element | null,
  scrollStep: number = 10,
  scrollInterval: number = 50
) => {
  const [fps, setFps] = React.useState(0);
  const lastFrameTimeRef = React.useRef<number | null>(null);
  const scrollIntervalRef = React.useRef<number | null>(null);
  const scrollPositionRef = React.useRef<number>(0);
  const fpsValuesRef = React.useRef<number[]>([]);

  const calculateFps = () => {
    const now = performance.now();
    if (lastFrameTimeRef.current !== null) {
      const timeDiff = now - lastFrameTimeRef.current;
      const currentFps = 1000 / timeDiff;
      setFps(currentFps);
      fpsValuesRef.current.push(currentFps);
      logger.info(`Current FPS: ${currentFps}`);
    }
    lastFrameTimeRef.current = now;
  };

  const startAutoScrolling = () => {
    if (!scrollElement) {
      logger.warn('Scroll container ref is not yet available.');
      return;
    }

    const contentHeight = scrollElement.scrollHeight;
    scrollPositionRef.current = 0;
    fpsValuesRef.current = [];
    scrollElement.scrollTo({
      top: 0,
      behavior: 'instant'
    });

    scrollIntervalRef.current = window.setInterval(() => {
      if (scrollPositionRef.current >= contentHeight) {
        stopAutoScrolling();
        return;
      }

      scrollPositionRef.current += scrollStep;
      logger.info(`Scroll position: ${scrollPositionRef.current}`);
      scrollElement.scrollTo({
        top: scrollPositionRef.current,
        behavior: 'instant'
      });
      calculateFps();
    }, scrollInterval);
  };

  const stopAutoScrolling = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
    logger.info('Stopping auto scrolling');
    const sum = fpsValuesRef.current.reduce((acc, v) => acc + v, 0);
    const averageFps = sum / fpsValuesRef.current.length;
    logger.info(`Average FPS: ${averageFps}`);
    fpsValuesRef.current = [];
  };

  return {
    fps,
    startAutoScrolling
  };
};
