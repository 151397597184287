import { I18n } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { TagGroupType, TagType } from './type';

export const DEFAULT_TAGS: Record<
  TagGroupType,
  { label: (i18n: I18n) => string; getTags: (i18n: I18n) => { label: string; type?: TagType }[] }
> = {
  PROJECT: {
    label: i18n => i18n._(msg`Project`),
    getTags: i18n => [
      {
        label: i18n._(msg`Template`),
        type: 'CONSTRUCTION_TEMPLATE_PROJECT'
      }
    ]
  },
  CONSTRUCTION_PROJECT_OWNER: {
    label: i18n => i18n._(msg`Owner`),
    getTags: i18n => []
  },
  CONSTRUCTION_PROJECT_ENGINEERING_OFFICE: {
    label: i18n => i18n._(msg`Engineering Office`),
    getTags: i18n => []
  }
};
