'use client';

import debounce from 'lodash/debounce';
import React from 'react';

export function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  delay: number,
  deps: any[] = []
) {
  const debouncedFn = React.useMemo(() => debounce(callback, delay), [delay, ...deps]);

  React.useEffect(() => {
    return () => {
      debouncedFn.cancel();
    };
  }, [debouncedFn]);

  return debouncedFn;
}
