import {
  formatDate as formatDateFns,
  formatDistanceToNow as formatDistanceToNowFns
} from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { LocaleCodeType } from '../../types';

// Map of locale codes to their commonly used date formats
const defaultDateFormats: Record<LocaleCodeType, string> = {
  en: 'MM/dd/yyyy', // Common US format
  de: 'dd.MM.yyyy' // Common German format
};

export const getDateFnsLocale = (localeCode: LocaleCodeType) => {
  if (localeCode === 'de') return de;
  return enUS;
};

export const formatDate = (date: Date, localeCode: LocaleCodeType, format?: string) => {
  return formatDateFns(date, format ?? defaultDateFormats[localeCode], {
    locale: getDateFnsLocale(localeCode)
  });
};

export const formatDistanceToNow = (date: Date, localeCode: LocaleCodeType) => {
  return formatDistanceToNowFns(date, {
    addSuffix: true,
    locale: getDateFnsLocale(localeCode)
  });
};

export { isSameDay, subDays } from 'date-fns';
