import { logger } from '../../logger';
import {
  PieceOfInformationDto,
  PieceOfInformationType,
  ProjectType,
  TableDataType
} from '../../types';
import { getFullFileName } from '../file';

export type PieceOfInformationForDto = {
  id: string;
  itemId: string;
  type: PieceOfInformationType;
  createdOn: Date | string;
  table: {
    id: string;
    name: string;
    dataType: TableDataType | null;
    views: {
      id: string;
      isDefault: boolean;
    }[];
  } | null;
  file: {
    id: string;
    name: string;
    extension: string;
  } | null;
  project: {
    id: string;
    name: string;
    description: string | null;
    type: ProjectType | null;
  } | null;
  conversation: {
    id: string;
    name: string;
    projectId: string | null;
  } | null;
  document: {
    id: string;
    title: string;
  } | null;
  emailDraft: {
    id: string;
    subject: string;
    name: string;
  } | null;
};

export const convertToPieceOfInformationDto = (
  pieceOfInformation: PieceOfInformationForDto
): PieceOfInformationDto | null => {
  try {
    const common = {
      id: pieceOfInformation.id,
      itemId: pieceOfInformation.itemId,
      createdOn:
        typeof pieceOfInformation.createdOn === 'string'
          ? new Date(pieceOfInformation.createdOn)
          : pieceOfInformation.createdOn
    };

    switch (pieceOfInformation.type) {
      case 'TABLE':
        if (!pieceOfInformation.table) {
          return null;
        }
        return {
          ...common,
          type: 'TABLE',
          name: pieceOfInformation.table.name,
          table: {
            id: pieceOfInformation.table.id,
            name: pieceOfInformation.table.name,
            dataType: pieceOfInformation.table.dataType,
            pieceOfInformationId: pieceOfInformation.id,
            viewId: pieceOfInformation.table.views.find(view => view.isDefault)!.id
          }
        };
      case 'FILE':
        if (!pieceOfInformation.file) {
          return null;
        }
        return {
          ...common,
          type: 'FILE',
          name: getFullFileName(pieceOfInformation.file.name, pieceOfInformation.file.extension),
          file: {
            id: pieceOfInformation.file.id,
            name: pieceOfInformation.file.name,
            extension: pieceOfInformation.file.extension,
            pieceOfInformationId: pieceOfInformation.id
          }
        };
      case 'PROJECT':
        if (!pieceOfInformation.project) {
          return null;
        }
        return {
          ...common,
          type: 'PROJECT',
          name: pieceOfInformation.project.name,
          project: {
            id: pieceOfInformation.project.id,
            name: pieceOfInformation.project.name,
            description: pieceOfInformation.project.description,
            type: pieceOfInformation.project.type,
            pieceOfInformationId: pieceOfInformation.id
          }
        };
      case 'CONVERSATION':
        if (!pieceOfInformation.conversation) {
          return null;
        }
        return {
          ...common,
          type: 'CONVERSATION',
          name: pieceOfInformation.conversation.name,
          conversation: {
            id: pieceOfInformation.conversation.id,
            name: pieceOfInformation.conversation.name,
            projectId: pieceOfInformation.conversation.projectId,
            pieceOfInformationId: pieceOfInformation.id
          }
        };
      case 'DOCUMENT':
        if (!pieceOfInformation.document) {
          return null;
        }
        return {
          ...common,
          type: 'DOCUMENT',
          name: pieceOfInformation.document.title,
          document: {
            id: pieceOfInformation.document.id,
            title: pieceOfInformation.document.title,
            pieceOfInformationId: pieceOfInformation.id
          }
        };
      case 'EMAIL_DRAFT':
        if (!pieceOfInformation.emailDraft) {
          return null;
        }
        return {
          ...common,
          type: 'EMAIL_DRAFT',
          name: pieceOfInformation.emailDraft.name,
          emailDraft: {
            id: pieceOfInformation.emailDraft.id,
            subject: pieceOfInformation.emailDraft.subject,
            pieceOfInformationId: pieceOfInformation.id,
            name: pieceOfInformation.emailDraft.name
          }
        };
      default:
        throw new Error('Invalid pieceOfInformation type');
    }
  } catch (error) {
    logger.error('Error converting pieceOfInformation to DTO', error);
    return null;
  }
};
