import { createCipheriv, createDecipheriv, randomBytes } from 'crypto';
import { Result, err, ok } from '@company/common/neverthrow';

export type CryptoError =
  | { type: 'EMPTY_VALUE'; message: string }
  | { type: 'INVALID_FORMAT'; message: string }
  | { type: 'MISSING_DATA'; message: string };

export const encrypt = (value: string, encryptionKey: string): Result<string, CryptoError> => {
  if (!value) {
    return err({ type: 'EMPTY_VALUE', message: 'Value to encrypt cannot be empty' });
  }

  try {
    const iv = randomBytes(16);
    const cipher = createCipheriv('aes-256-cbc', encryptionKey, iv);
    let encrypted = cipher.update(value, 'utf8', 'hex');
    encrypted += cipher.final('hex');
    return ok(`${iv.toString('hex')}:${encrypted}`);
  } catch (error) {
    console.error('Failed to encrypt data', error);
    return err({ type: 'MISSING_DATA', message: 'Failed to encrypt data' });
  }
};

export const decrypt = (value: string, encryptionKey: string): Result<string, CryptoError> => {
  if (!value || !value.includes(':')) {
    return err({ type: 'INVALID_FORMAT', message: 'Invalid encrypted value format' });
  }

  const [iv, encrypted] = value.split(':');
  if (!iv || !encrypted) {
    return err({ type: 'MISSING_DATA', message: 'Missing IV or encrypted data' });
  }

  try {
    const decipher = createDecipheriv('aes-256-cbc', encryptionKey, Buffer.from(iv, 'hex'));
    let decrypted = decipher.update(encrypted, 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return ok(decrypted);
  } catch (error) {
    return err({ type: 'MISSING_DATA', message: 'Failed to decrypt data' });
  }
};
