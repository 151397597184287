import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { EnumMap } from '../utils';

export const mailboxProviderSchema = z.enum(['OUTLOOK']);

export type MailboxProvider = z.infer<typeof mailboxProviderSchema>;

export const MAILBOX_PROVIDERS: EnumMap<MailboxProvider> = {
  OUTLOOK: {
    id: 'OUTLOOK',
    getLabel: i18n => i18n(msg`Outlook`)
  }
};
